import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, map, of, switchMap } from 'rxjs';
import { AppState } from '../../app-state/app.state';
import { MAP_MAX_ZOOM } from '../map.models';
import { SetActiveBaseLayer, SetBaseLayers } from './base-layer.actions';
import { BaseLayer } from './base-layer.models';
import { BaseLayerState } from './base-layer.state';

@Injectable({
  providedIn: 'root',
})
export class BaseLayerService {
  constructor(private store: Store) {}

  getBaseLayers() {
    return this.store.select(BaseLayerState.baseLayers).pipe(
      switchMap((layers) => {
        return isDefined(layers) ? of(layers) : this.createAndCacheBaseLayers();
      }),
    );
  }

  private createAndCacheBaseLayers() {
    return this.createBaseLayers().pipe(
      switchMap((layers) => {
        const activeLayer = this.store.selectSnapshot(BaseLayerState.activeBaseLayer);
        const newActiveLayer = layers.find((l) => l.name === activeLayer?.name) ?? layers[0];

        return this.store.dispatch(new SetActiveBaseLayer(newActiveLayer)).pipe(map(() => layers));
      }),
      switchMap((layers) => {
        return this.store.dispatch(new SetBaseLayers(layers)).pipe(map(() => layers));
      }),
    );
  }

  private createBaseLayers(): Observable<BaseLayer[]> {
    return this.store.selectOnce(AppState.endpoints).pipe(
      map((endpoints) => endpoints.maps),
      map((mapsEndpoint) => {
        const layers: BaseLayer[] = [
          {
            name: 'Satellite',
            templateUrl: mapsEndpoint.url.replace('{style}', 'satellite'),
            maxZoom: MAP_MAX_ZOOM,
          },
          {
            name: 'Street',
            templateUrl: mapsEndpoint.url.replace('{style}', 'default'),
            maxZoom: MAP_MAX_ZOOM,
          },
        ];

        return layers;
      }),
    );
  }
}
