import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { TierResult, getGPUTier } from 'detect-gpu';
import { catchError, distinctUntilChanged, filter, from, map, of, switchMap, tap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import { User } from '../auth/auth.models';
import { AuthState } from '../auth/auth.state';
import { GET_CONNECT_REGION_URL } from '../connect/get-connect-region-url';
import { Logger, injectLogger } from '../logging/logger';
import { initializeAptrinsic } from './aptrinsic.init';
import { AptrinsicSdk } from './aptrinsic.sdk';
import { GainsightEvents } from './gainisght-events';
import { ConnectLicence, ProjectInfo } from './gainsight.models';

// https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  private readonly store = inject(Store);
  private readonly http = inject(HttpClient);
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);

  readonly sdk = inject(AptrinsicSdk);
  readonly logger = injectLogger(Logger, 'GainsightService');

  initialize(window: Window, tag: string) {
    initializeAptrinsic(window, tag);
  }

  identifyUser() {
    return this.store.select(AuthState.user).pipe(
      filter((user): user is User => isDefined(user)),
      tap((user) => this.sdk.identify(user, { id: user.id })),
    );
  }

  trackProject() {
    return this.getProject().pipe(
      switchMap((project) => this.getProjectLicence(project)),
      map(([project, licence]) => this.getProjectInfo(project, licence)),
      tap((projectInfo: ProjectInfo) => {
        this.sdk.track(GainsightEvents.projectSelected, projectInfo);
      }),
    );
  }

  detectGpu() {
    return from(getGPUTier()).pipe(
      catchError((error) => {
        this.logger.info('GPU detection failed', {}, error);
        return of({
          tier: 0,
          type: 'WEBGL_UNSUPPORTED',
        } satisfies TierResult);
      }),
      tap((result: TierResult) => {
        this.sdk.track(GainsightEvents.gpuDetected, result);
      }),
    );
  }

  private getProject() {
    return this.store.select(AppState.project).pipe(
      filter((project) => isDefined(project)),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    );
  }

  private getProjectLicence(project: ConnectProject) {
    return this.getConnectRegionUrl$(`projects/${project.id}/license`).pipe(
      switchMap((url) => this.http.get<ConnectLicence>(url)),
      map((licence) => [project, licence] as [ConnectProject, ConnectLicence]),
    );
  }

  private getProjectInfo(project: ConnectProject, licence: ConnectLicence) {
    const account = licence.accounts.find((acc) => acc.primary) ?? licence.accounts[0];
    return {
      projectId: project.id,
      projectName: project.name ?? '',
      accountId: account.accountId,
      accountName: account.name,
      accountSource: account.source,
      ecomSource: licence.ecomSource,
    } satisfies ProjectInfo;
  }
}
