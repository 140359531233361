import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest, combineLatestWith, filter, map } from 'rxjs';
import { Role } from '../../auth/auth.models';
import { AuthState } from '../../auth/auth.state';
import { ScandataSelectedState } from '../../scandata/scandata-selected.state';
import { UnselectAllScandataModels, UnselectScandataModel } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { DownloadSelectComponent } from '../download/download-select/download-select.component';
import { MultiSelectedComponent } from './multi-selected/multi-selected.component';
import { SingleSelectedComponent } from './single-selected/single-selected.component';

@UntilDestroy()
@Component({
  selector: 'sd-selected-details',
  standalone: true,
  imports: [CommonModule, MultiSelectedComponent, SingleSelectedComponent, DownloadSelectComponent],
  templateUrl: './selected-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedDetailsComponent {
  @Select(ScandataState.selected) private selected$!: Observable<ScandataModel[]>;
  @Select(ScandataSelectedState.ids) private selectedIds$!: Observable<string[]>;
  @Select(AuthState.userRole) private userRole$!: Observable<Role>;

  readonly$!: Observable<boolean>;
  singleSelected$!: Observable<ScandataModel>;
  multiSelected$!: Observable<ScandataModel[]>;
  showSingleSelected$!: Observable<boolean>;
  showMultiSelected$!: Observable<boolean>;
  showDownload$ = new BehaviorSubject(false);

  constructor(private store: Store) {
    this.setupObservables();
  }

  showDownload(visible: boolean) {
    this.showDownload$.next(visible);
  }

  unselectScan(scan: ScandataModel) {
    this.store.dispatch(new UnselectScandataModel(scan.id));
  }

  unselectAllScans() {
    this.store.dispatch(new UnselectAllScandataModels());
  }

  private setupObservables(): void {
    this.singleSelected$ = this.selected$.pipe(
      filter((selected) => selected.length === 1),
      map((selected) => selected[0]),
    );

    this.showSingleSelected$ = this.selected$.pipe(
      combineLatestWith(this.showDownload$),
      map(([selected, showDownload]) => selected.length === 1 && !showDownload),
    );

    this.showMultiSelected$ = this.selected$.pipe(
      combineLatestWith(this.showDownload$),
      map(([selected, showDownload]) => selected.length > 1 && !showDownload),
    );

    this.multiSelected$ = combineLatest([this.selected$, this.selectedIds$]).pipe(
      map(([scans, sortedIds]) => {
        return scans.sort(function (a, b) {
          return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
        });
      }),
    );

    this.readonly$ = this.userRole$.pipe(map((userRole) => userRole !== Role.Admin));
  }
}
