import { PointcloudStatus } from './scandata.models';

export interface ScandataQuery {
  sorting: Sorting;
  paging: Paging;
  filters: Filters;
}

export interface Sorting {
  sortBy: string;
  sortDirection: 'asc' | 'desc' | '';
}

export interface Paging {
  pageIndex: number;
  pageSize: number;
}

export const DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE = true;

export interface Filters {
  name?: string;
  captureFromDate?: Date;
  captureToDate?: Date;
  status?: PointcloudStatus[];
  scannerType?: string;
  tags?: string[];
  tagsMatchAll: boolean;
  uploadedBy?: string[];
  isClassified?: true;
  containsStations?: true;
}
