import { ConsoleApiName } from '../../tools/display';
import { toStackTraceString, NO_ERROR_STACK_PRESENT_MESSAGE } from '../error/error';
import { getExperimentalFeatures } from '../../tools/experimentalFeatures';
import { INTAKE_SITE_STAGING, INTAKE_SITE_US1_FED } from '../configuration';
import { Observable } from '../../tools/observable';
import { timeStampNow } from '../../tools/utils/timeUtils';
import { displayIfDebugEnabled, startMonitorErrorCollection } from '../../tools/monitor';
import { sendToExtension } from '../../tools/sendToExtension';
import { startsWith, arrayFrom, includes, assign } from '../../tools/utils/polyfills';
import { performDraw } from '../../tools/utils/numberUtils';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { combine } from '../../tools/mergeInto';
import { computeStackTrace } from '../error/computeStackTrace';
import { getConnectivity } from '../connectivity';
import { TelemetryType } from './rawTelemetryEvent.types';
var ALLOWED_FRAME_URLS = [
    'https://www.datadoghq-browser-agent.com',
    'https://www.datad0g-browser-agent.com',
    'https://d3uc069fcn7uxw.cloudfront.net',
    'https://d20xtzwzcl0ceb.cloudfront.net',
    'http://localhost',
    '<anonymous>',
];
var TELEMETRY_EXCLUDED_SITES = [INTAKE_SITE_US1_FED];
var telemetryConfiguration = { maxEventsPerPage: 0, sentEventCount: 0, telemetryEnabled: false, telemetryConfigurationEnabled: false };
var onRawTelemetryEventCollected;
export function startTelemetry(telemetryService, configuration) {
    var contextProvider;
    var observable = new Observable();
    telemetryConfiguration.telemetryEnabled =
        !includes(TELEMETRY_EXCLUDED_SITES, configuration.site) && performDraw(configuration.telemetrySampleRate);
    telemetryConfiguration.telemetryConfigurationEnabled =
        telemetryConfiguration.telemetryEnabled && performDraw(configuration.telemetryConfigurationSampleRate);
    var runtimeEnvInfo = getRuntimeEnvInfo();
    onRawTelemetryEventCollected = function (rawEvent) {
        if (telemetryConfiguration.telemetryEnabled) {
            var event_1 = toTelemetryEvent(telemetryService, rawEvent, runtimeEnvInfo);
            observable.notify(event_1);
            sendToExtension('telemetry', event_1);
        }
    };
    startMonitorErrorCollection(addTelemetryError);
    assign(telemetryConfiguration, {
        maxEventsPerPage: configuration.maxTelemetryEventsPerPage,
        sentEventCount: 0,
    });
    function toTelemetryEvent(telemetryService, event, runtimeEnvInfo) {
        return combine({
            type: 'telemetry',
            date: timeStampNow(),
            service: telemetryService,
            version: "5.14.0",
            source: 'browser',
            _dd: {
                format_version: 2,
            },
            telemetry: combine(event, {
                runtime_env: runtimeEnvInfo,
                connectivity: getConnectivity(),
            }),
            experimental_features: arrayFrom(getExperimentalFeatures()),
        }, contextProvider !== undefined ? contextProvider() : {});
    }
    return {
        setContextProvider: function (provider) {
            contextProvider = provider;
        },
        observable: observable,
        enabled: telemetryConfiguration.telemetryEnabled,
    };
}
function getRuntimeEnvInfo() {
    return {
        is_local_file: window.location.protocol === 'file:',
        is_worker: 'WorkerGlobalScope' in self,
    };
}
export function startFakeTelemetry() {
    var events = [];
    assign(telemetryConfiguration, {
        maxEventsPerPage: Infinity,
        sentEventCount: 0,
    });
    onRawTelemetryEventCollected = function (event) {
        events.push(event);
    };
    return events;
}
export function resetTelemetry() {
    onRawTelemetryEventCollected = undefined;
}
/**
 * Avoid mixing telemetry events from different data centers
 * but keep replicating staging events for reliability
 */
export function isTelemetryReplicationAllowed(configuration) {
    return configuration.site === INTAKE_SITE_STAGING;
}
export function addTelemetryDebug(message, context) {
    displayIfDebugEnabled(ConsoleApiName.debug, message, context);
    addTelemetry(assign({
        type: TelemetryType.log,
        message: message,
        status: "debug" /* StatusType.debug */,
    }, context));
}
export function addTelemetryError(e, context) {
    addTelemetry(assign({
        type: TelemetryType.log,
        status: "error" /* StatusType.error */,
    }, formatError(e), context));
}
export function addTelemetryConfiguration(configuration) {
    if (telemetryConfiguration.telemetryConfigurationEnabled) {
        addTelemetry({
            type: TelemetryType.configuration,
            configuration: configuration,
        });
    }
}
function addTelemetry(event) {
    if (onRawTelemetryEventCollected && telemetryConfiguration.sentEventCount < telemetryConfiguration.maxEventsPerPage) {
        telemetryConfiguration.sentEventCount += 1;
        onRawTelemetryEventCollected(event);
    }
}
export function formatError(e) {
    if (e instanceof Error) {
        var stackTrace = computeStackTrace(e);
        return {
            error: {
                kind: stackTrace.name,
                stack: toStackTraceString(scrubCustomerFrames(stackTrace)),
            },
            message: stackTrace.message,
        };
    }
    return {
        error: {
            stack: NO_ERROR_STACK_PRESENT_MESSAGE,
        },
        message: "".concat("Uncaught" /* NonErrorPrefix.UNCAUGHT */, " ").concat(jsonStringify(e)),
    };
}
export function scrubCustomerFrames(stackTrace) {
    stackTrace.stack = stackTrace.stack.filter(function (frame) { return !frame.url || ALLOWED_FRAME_URLS.some(function (allowedFrameUrl) { return startsWith(frame.url, allowedFrameUrl); }); });
    return stackTrace;
}
