<div class="flex flex-col border-b border-gray-1">
  @if (isLoading$ | async) {
    <div class="w-full relative">
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    </div>
  }

  <div class="flex flex-row items-center pl-3 pt-2 pr-2 text-xl font-medium">
    Scan Data
    <div class="flex flex-1 justify-end">
      <button
        id="3d-global-settings"
        class="text-gray-8"
        modus-icon-button
        (click)="settingsClick.emit()"
      >
        <md-icon>settings</md-icon>
      </button>
    </div>
  </div>

  <div class="pl-3 pr-[1.2rem] pb-2">
    <mat-select
      id="3d-list-filter"
      class="modus-dropdown"
      panelClass="modus-dropdown-panel"
      [formControl]="filterOptionControl"
      disableRipple
    >
      <mat-option [value]="scan3dListFilterOption.ShowAll">All Scan Data</mat-option>
      <mat-option [value]="scan3dListFilterOption.ShowSelected">Selected</mat-option>
    </mat-select>
  </div>
</div>

@if (hasCurrentStation$ | async) {
  <div class="flex flex-row items-center pl-3 border-b border-gray-1 bg-blue-light text-white">
    <div
      modus-tooltip="Station: {{ currentStationName$ | async }}"
      class="w-full overflow-hidden text-ellipsis whitespace-nowrap mr-1"
    >
      Station: {{ currentStationName$ | async }}
    </div>
    <div class="flex justify-end mr-2">
      <button
        id="3d-exit-station"
        modus-tooltip="Exit Station"
        class="bg-blue-light text-white enabled:hover:text-white"
        modus-icon-button
        (click)="onExitStationClick()"
      >
        <md-icon>close</md-icon>
      </button>
    </div>
  </div>
}

@if (scanLoadError$ | async) {
  <div class="p-4">
    <modus-alert
      class="w-full"
      message="Error loading scan data. Please try again later."
      type="error"
    ></modus-alert>
  </div>
}

<div class="pb-3 overflow-y-auto pt-1" cdkScrollable>
  <sd-scandata-tree-view
    [scandata]="(data$ | async)!"
    (modelDetailClick)="onModelDetailClick($event)"
  ></sd-scandata-tree-view>

  @if (showScandataEmpty$ | async) {
    <sd-scandata-empty [reason]="scandataEmptyReason$ | async"></sd-scandata-empty>
  }
</div>
