import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RouteState } from './route.state';

export const redirectToLastVisitedRoute: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const lastVisitedRoute = store.selectSnapshot(RouteState.route);

  router.navigate([lastVisitedRoute]);

  return false;
};
