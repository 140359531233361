import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, shareReplay, take } from 'rxjs';
import { ScandataModel } from '../../../scandata/scandata.models';

import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClassificationScheme } from '../../../classification/classification-scheme.model';
import { Classification } from '../../../classification/classification.model';
import { ClassificationService } from '../../../classification/classification.service';
import { ClassificationGraphComponent } from '../../../options-panel/selected-details/single-selected/classification/classification-graph/classification-graph.component';
import { PercentagePipe } from '../../../pipes/percentage.pipe';
import { colorHexStripAlpha } from '../../../utils/color-converter';
import { Scan3dStyle } from '../../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-classification',
  standalone: true,
  imports: [
    CommonModule,
    ClassificationGraphComponent,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    PercentagePipe,
    ReactiveFormsModule,
    ModusSwitchModule,
    ColorPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dClassificationComponent implements OnChanges {
  @Input() scandataModel!: ScandataModel;
  @Input() scan3dStyle!: Scan3dStyle;
  @Output() styleChange = new EventEmitter<Scan3dStyle>();

  hasClassifications$!: Observable<boolean>;
  classifications$!: Observable<Classification[]>;
  activeClassification!: Classification | null;
  unallocatedClassificationCount$!: Observable<number>;
  showClassificationControl: FormControl = new FormControl<boolean>(false, { nonNullable: true });
  colorPickerOpen = false;

  constructor(private classificationService: ClassificationService) {
    this.subscribeToFormChanges();
  }

  ngOnChanges() {
    if (isNil(this.scandataModel) || isNil(this.scan3dStyle)) return;

    this.classifications$ = this.getModelClassifications().pipe(shareReplay(1));
    this.setFormValue();
  }

  setActiveClassification(classification: Classification | null = null) {
    if (this.colorPickerOpen) return;

    this.activeClassification = classification;
  }

  isActiveClassification(classification: Classification) {
    return classification === this.activeClassification;
  }

  changeVisibility(classification: Classification) {
    const scheme = classification.classificationScheme;
    scheme.visible = !scheme.visible;

    this.getClassificationSchemeChanges(scheme)
      .pipe(take(1))
      .subscribe((schemes) => {
        this.updateModelStyle({ classificationSchemes: schemes });
      });
  }

  changeColor(color: string, classification: Classification) {
    const scheme = classification.classificationScheme;
    scheme.rgba = color.replace('#', '').toUpperCase();

    this.getClassificationSchemeChanges(scheme)
      .pipe(take(1))
      .subscribe((schemes) => {
        this.updateModelStyle({ classificationSchemes: schemes });
      });
  }

  stripAlpha(rgba: string) {
    return colorHexStripAlpha(rgba);
  }

  colorPickerToggle(open: boolean) {
    this.activeClassification = null;
    this.colorPickerOpen = open;
  }

  private subscribeToFormChanges() {
    this.showClassificationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.updateModelStyle({ showClassification: show });
    });
  }

  private setFormValue() {
    this.hasClassifications$ = this.classifications$.pipe(
      map((classifications) => classifications.length > 0),
    );

    this.unallocatedClassificationCount$ = this.classifications$.pipe(
      map((modelClassifications) => {
        const schemes = this.scan3dStyle.classificationSchemes;
        return schemes.length - modelClassifications.length;
      }),
    );

    this.showClassificationControl.setValue(this.scan3dStyle.showClassification, {
      emitEvent: false,
    });
  }

  private getModelClassifications(): Observable<Classification[]> {
    return this.classificationService.getClassifications(this.scandataModel).pipe(
      map((classifications) => {
        const schemes = this.scan3dStyle.classificationSchemes;

        return classifications.map((classification) => {
          const classificationScheme =
            schemes.find((scheme) => scheme.id === classification.classificationScheme.id) ??
            classification.classificationScheme;

          return {
            ...classification,
            classificationScheme,
          };
        });
      }),
    );
  }

  private getClassificationSchemeChanges(classificationScheme: ClassificationScheme) {
    return this.getModelClassifications().pipe(
      map((schemes) => {
        return schemes.map((scheme) => {
          if (scheme.classificationScheme.id === classificationScheme.id) {
            return classificationScheme;
          }
          return scheme.classificationScheme;
        });
      }),
    );
  }

  private updateModelStyle(style: Partial<Scan3dStyle>) {
    const mergedStyle: Scan3dStyle = {
      ...this.scan3dStyle,
      ...style,
    };
    this.styleChange.emit(mergedStyle);
  }
}
