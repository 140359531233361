import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { filter, map, Observable, shareReplay, switchMap } from 'rxjs';
import { AppContext } from './app-state/app.models';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { DialogService } from './dialog/dialog.service';
import { AppHostComponent } from './hosts/app-host/app-host.component';
import { ExtHostComponent } from './hosts/ext-host/ext-host.component';
import { OutOfQuotaDialogComponent } from './quota/out-of-quota-dialog.component';
import { ProjectQuotaService } from './quota/project-quota.service';

@Component({
  selector: 'sd-root',
  standalone: true,
  imports: [CommonModule, AppHostComponent, ExtHostComponent],
  template: `
    @if ((extension$ | async) === true) {
      <sd-ext-host></sd-ext-host>
    } @else {
      <sd-app-host></sd-app-host>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
})
export class AppComponent {
  extension$: Observable<boolean>;

  @Select(AppState.context) private context$!: Observable<AppContext>;

  constructor(
    appService: AppService,
    private projectQuotaService: ProjectQuotaService,
    private dialogService: DialogService,
  ) {
    this.extension$ = this.context$.pipe(
      filter((ctx) => ctx !== AppContext.Unknown),
      map((ctx) => ctx === AppContext.ConnectExtension),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    appService.loadAppData().subscribe();

    this.checkProjectQuota();
  }

  private checkProjectQuota() {
    this.projectQuotaService
      .getProjectQuota()
      .pipe(
        filter((quota) => quota.quotaUsedByAccount >= quota.quota),
        switchMap(() => {
          return this.dialogService.showComponent(OutOfQuotaDialogComponent, {
            disableClose: true,
          });
        }),
      )
      .subscribe();
  }
}
