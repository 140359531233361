import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SimpleChangesTyped, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, map } from 'rxjs';
import { DownloadFile, DownloadProgress, DownloadStatus } from '../download.model';
import { DownloadState } from '../download.state';

@UntilDestroy()
@Component({
  selector: 'sd-download-progress',
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './download-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadProgressComponent implements OnChanges {
  @Input() downloadFiles!: DownloadFile[] | null;
  @Input() progressTooltipPrefix!: string;

  downloadStatus = DownloadStatus;

  downloadProgress$!: Observable<DownloadProgress | null>;
  progressTooltip$!: Observable<string>;

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChangesTyped<DownloadProgressComponent>) {
    const downloadFiles = changes.downloadFiles.currentValue;
    if (downloadFiles) {
      this.downloadProgress$ = this.store.select(DownloadState.getDownloadsProgress(downloadFiles));

      this.progressTooltip$ = this.downloadProgress$.pipe(
        map((progress) => {
          if (isNil(progress)) return '';
          return `${this.progressTooltipPrefix} ${progress.percentageComplete}%`;
        }),
      );
    }
  }
}
