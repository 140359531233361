import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { distinctUntilChanged, map } from 'rxjs';
import { BaseLayer } from '../base-layer/base-layer.models';
import { MapTool } from '../map.state';

@UntilDestroy()
@Component({
  selector: 'sd-map-toolbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ModusButtonModule, ModusIconModule, MatSelectModule],
  templateUrl: './map-toolbar.component.html',
})
export class MapToolbarComponent {
  @Input() baseLayers!: BaseLayer[] | null;
  @Input() set activeBaseLayer(value: BaseLayer | null) {
    this.selectedBaseLayer = value;
    if (isNil(this.selectedBaseLayer)) {
      return;
    }
    this.layerOptionControl.setValue(this.selectedBaseLayer.name, { emitEvent: false });
  }
  @Input() set activeMapTool(mapTool: MapTool) {
    this.panSelectActive = mapTool === MapTool.Default;
    this.rectangleSelectActive = mapTool === MapTool.RectangleSelect;
    this.polygonSelectActive = mapTool === MapTool.PolygonSelect;
  }

  @Output() mapToolSelected = new EventEmitter<MapTool>();
  @Output() zoomInClick = new EventEmitter();
  @Output() zoomOutClick = new EventEmitter();
  @Output() fitToViewClick = new EventEmitter();
  @Output() baseLayerChange = new EventEmitter<BaseLayer>();

  selectedBaseLayer!: BaseLayer | null;
  layerOptionControl = new FormControl<string | null>(null);
  panSelectActive = false;
  rectangleSelectActive = false;
  polygonSelectActive = false;

  constructor() {
    this.subscribeBaseLayerSelectionChange();
  }

  panSelectClick() {
    this.mapToolSelected.emit(MapTool.Default);
  }

  rectangleSelectClick() {
    const mapTool = this.rectangleSelectActive ? MapTool.Default : MapTool.RectangleSelect;
    this.mapToolSelected.emit(mapTool);
  }

  polygonSelectClick() {
    const mapTool = this.polygonSelectActive ? MapTool.Default : MapTool.PolygonSelect;
    this.mapToolSelected.emit(mapTool);
  }

  private subscribeBaseLayerSelectionChange() {
    this.layerOptionControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        map((selectedLayerName) => {
          return this.baseLayers?.find((layer) => layer.name === selectedLayerName);
        }),
        untilDestroyed(this),
      )
      .subscribe((layer) => {
        if (isNil(layer)) return;

        this.baseLayerChange.emit(layer);
      });
  }
}
