<div class="flex flex-col h-full">
  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Download Scan Package</div>
    <button
      id="options-panel-download-close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="closeClicked.emit()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <div class="flex-1 overflow-y-scroll p-4">
    <sd-download-select-list></sd-download-select-list>
  </div>
</div>
