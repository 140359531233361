import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ScandataModel } from '../scandata/scandata.models';
import { ClearHost3dScandata, SetHost3dScandata } from './host-3d.actions';

export interface Host3dStateModel {
  scandata?: ScandataModel[];
}

const defaultState: Host3dStateModel = {};

@State<Host3dStateModel>({
  name: 'host3dState',
  defaults: defaultState,
})
@Injectable()
export class Host3dState {
  @Selector() static scandata(state: Host3dStateModel): ScandataModel[] | undefined {
    return state.scandata;
  }

  @Action(SetHost3dScandata) setHost3dScandata(
    ctx: StateContext<Host3dStateModel>,
    { scandata }: SetHost3dScandata,
  ) {
    ctx.patchState({ scandata });
  }

  @Action(ClearHost3dScandata) clearHost3dScandata(ctx: StateContext<Host3dStateModel>) {
    ctx.patchState({ scandata: undefined });
  }
}
