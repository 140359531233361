import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { Observable } from 'rxjs';
import { PatchScandataModels } from '../../scandata/scandata.actions';
import { ScandataState } from '../../scandata/scandata.state';
import { Scan3dStyle } from '../models/scan-3d-style';
import { Scan3dStylingComponent } from '../scan-3d-styling/scan-3d-styling.component';
import { SetGlobalStyle } from '../scan-3d.actions';
import { Scan3dService } from '../scan-3d.service';
import { Scan3dState } from '../scan-3d.state';
import { Scan3dClassificationComponent } from './scan-3d-classification/scan-3d-classification.component';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusButtonModule,
    ModusIconModule,
    MatTabsModule,
    Scan3dClassificationComponent,
    Scan3dStylingComponent,
  ],
  templateUrl: './scan-3d-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dSettingsComponent {
  @Output() exitSettingsClick = new EventEmitter<void>();

  scan3dStyle$!: Observable<Scan3dStyle>;

  constructor(
    private store: Store,
    scan3dService: Scan3dService,
  ) {
    this.scan3dStyle$ = scan3dService.getGlobalStyle();
  }

  onStyleChange(style: Scan3dStyle) {
    // Turn eye dome lighting on when classification color is switched on
    const globalStyle = this.store.selectSnapshot(Scan3dState.globalStyle);
    const classificationShowing = globalStyle?.showClassification === true;
    if (
      classificationShowing === false &&
      style.showClassification === true &&
      style.showEyeDomeLighting === false
    ) {
      style.showEyeDomeLighting = true;
    }

    const scans = this.store
      .selectSnapshot(ScandataState.scandata)
      .filter((scan) => isDefined(scan.scan3dStyle))
      .map((scan) => {
        return { ...scan, scan3dStyle: undefined };
      });

    this.store.dispatch([new SetGlobalStyle(style), new PatchScandataModels(scans)]);
  }
}
