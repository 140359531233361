export enum ConnectFile3dStatus {
  CheckingStatus = 'CheckingStatus',
  IngestionError = 'IngestionError',
  QuotaExceeded = 'QuotaExceeded',
  ReadyToRefresh = 'ReadyToRefresh',
  Tiling = 'Tiling',
  TilingError = 'TilingError',
  Loading = 'Loading',
  Loaded = 'Loaded',
  LoadingFailed = 'LoadingFailed',
  Unloading = 'Unloading',
  Unloaded = 'Unloaded',
}
