import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { SimpleChangesTyped } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusCheckboxModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from '../../scandata-list/scandata-empty/scandata-empty.component';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';
import { MapFilterOption, MapScandataModel } from '../map.models';

@Component({
  selector: 'sd-map-list',
  standalone: true,
  imports: [
    CommonModule,
    ScandataEmptyComponent,
    MatProgressBarModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSelectModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    ScrollingModule,
    MatSelectModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './map-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapListComponent implements OnChanges {
  @Input() scanLoadError = false;
  @Input() isLoading = false;
  @Input() mapScandata: MapScandataModel[] = [];
  @Input() filterOption!: MapFilterOption;

  @Input() showScandataEmpty!: boolean;
  @Input() scandataEmptyReason!: ScandataEmptyReason;

  @Output() changeItemVisibilityClicked = new EventEmitter<ScandataModel>();
  @Output() itemClicked = new EventEmitter<ScandataModel>();
  @Output() itemChecked = new EventEmitter<ScandataModel>();
  @Output() zoomToItemClicked = new EventEmitter<ScandataModel>();
  @Output() filterOptionChanged = new EventEmitter<MapFilterOption>();

  mapFilterOption = MapFilterOption;
  filterOptionControl = new FormControl<MapFilterOption>(MapFilterOption.All);

  constructor() {
    this.filterOptionControl.valueChanges.subscribe((value) => {
      if (value) this.filterOptionChanged.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges & SimpleChangesTyped<MapListComponent>): void {
    if (changes.filterOption) {
      this.filterOptionControl.setValue(changes.filterOption.currentValue ?? null);
    }
  }

  scanHasError(scan: ScandataModel) {
    return scan.pointcloudStatus === PointcloudStatus.Failed;
  }

  scanNotReady(scan: ScandataModel) {
    return scan.pointcloudStatus === PointcloudStatus.Processing;
  }

  getScanErrorMessage(scan: ScandataModel) {
    if (scan.pointcloudStatus === PointcloudStatus.Processing) {
      return 'Not ready for viewing';
    }

    if (scan.pointcloudStatus === PointcloudStatus.Failed) {
      return 'Failed to ingest';
    }

    return null;
  }
}
