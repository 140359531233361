import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export enum ScandataEmptyReason {
  NoUploads,
  NoFilterResults,
  NoGeolocated,
  NoSelected,
}

@UntilDestroy()
@Component({
  selector: 'sd-scandata-empty',
  standalone: true,
  templateUrl: './scandata-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataEmptyComponent {
  @Input() reason!: ScandataEmptyReason | null;

  emptyReason = ScandataEmptyReason;
}
