import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  EMPTY,
  catchError,
  exhaustMap,
  filter,
  forkJoin,
  from,
  map,
  merge,
  of,
  pairwise,
  switchMap,
} from 'rxjs';
import { ConnectFile, File3DStatus } from 'trimble-connect-workspace-api';
import { Host3dService } from '../connect-3d-ext/host-3d.service';
import { Logger, injectLogger } from '../logging/logger';
import { ProjectQuotaService } from '../quota/project-quota.service';
import {
  PointcloudAPIStatus,
  ScandataDisplayStatus,
  ScandataModel,
} from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { ScandataState } from '../scandata/scandata.state';
import { ConnectIngestionService } from './connect-ingestion.service';
import { ConnectWorkspace } from './connect.models';
import { ConnectService } from './connect.service';
import { ConnectFile3dStatus } from './models/connect-file-3d-status';
import { ConnectFileSelectEvent } from './models/connect-file-select-event';

@Injectable({
  providedIn: 'root',
})
export class Connect3dPanelService {
  private logger = injectLogger(Logger, 'Connect3dPanelService');
  private workspace!: ConnectWorkspace;

  constructor(
    private connectService: ConnectService,
    private ingestionService: ConnectIngestionService,
    private projectQuotaService: ProjectQuotaService,
    private scandataService: ScandataService,
    private host3dService: Host3dService,
    private store: Store,
  ) {
    this.subscribeToDisplayedScans();
    this.subscribeToHiddenScans();
  }

  public async subscribeToConnectEvents() {
    this.workspace = await this.connectService.getWorkspace();
    this.subscribeToIconClickAndTile();
    this.subscribeToIconClickAndShow();
  }

  private subscribeToIconClickAndTile() {
    this.getScanForFileClick()
      .pipe(
        filter(({ scan }) => isNil(scan) || scan.status === PointcloudAPIStatus.Failed),
        switchMap(({ file }) => this.checkQuota(file)),
        exhaustMap((file) =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.Tiling).pipe(
            switchMap(() => this.getFileDownloadUrl(file)),
            switchMap((downloadUrl) => this.createIngestion(file, downloadUrl)),
            switchMap((file) => this.setup3dIcon(file.id, ConnectFile3dStatus.ReadyToRefresh)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToIconClickAndShow() {
    this.getScanForFileClick()
      .pipe(
        filter(({ scan }) => isDefined(scan)),
        switchMap(({ file, scan }) =>
          this.setup3dIconForScan(file.id, scan as ScandataModel).pipe(
            map(() => ({ file, scan: scan as ScandataModel })),
          ),
        ),
        filter(({ scan }) => scan.status === PointcloudAPIStatus.Ready),
        switchMap(({ file, scan }) => this.toggleScanDisplay(file, scan)),
      )
      .subscribe();
  }

  private getScanForFileClick() {
    return this.observeIconClicked().pipe(
      filter((fileEvent) => this.allowFileClick(fileEvent.file)),
      switchMap((fileEvent) => this.getScan(fileEvent.file)),
    );
  }

  private observeIconClicked() {
    return this.workspace.event$.pipe(
      filter((workspaceEvent) => workspaceEvent.id === 'extension.fileViewClicked'),
      map((workspaceEvent) => workspaceEvent.data as ConnectFileSelectEvent),
      filter((fileEvent) => fileEvent.source.startsWith('3dviewer')),
    );
  }

  private allowFileClick(file: ConnectFile) {
    return (
      isNil(file.status) ||
      file.status === 'assimilationFailed' ||
      file.status === 'assimilationBusy' ||
      file.status === 'loaded' ||
      file.status === 'loadingFailed' ||
      file.status === 'unloaded'
    );
  }

  private getScan(file: ConnectFile) {
    const storeScan$ = this.store.selectOnce(ScandataState.getScanForExternalFile(file.id));
    const getScan$ = this.setup3dIcon(file.id, ConnectFile3dStatus.CheckingStatus).pipe(
      switchMap(() => this.ingestionService.getScan(file)),
      map((scan) => ({ file, scan })),
      catchError((err) => this.handleError(file, err)),
    );

    return storeScan$.pipe(
      switchMap((storeScan) =>
        this.shouldGetScan(storeScan) ? getScan$ : of({ file, scan: storeScan }),
      ),
    );
  }

  private shouldGetScan(scan?: ScandataModel) {
    return (
      isNil(scan) ||
      (scan.status !== PointcloudAPIStatus.Ready && scan.status !== PointcloudAPIStatus.Failed)
    );
  }

  private getFileDownloadUrl(file: ConnectFile) {
    return this.connectService.getFileDownloadUrl(file).pipe(
      map((download) => download.url),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private getFile3dStatus(status: ConnectFile3dStatus): File3DStatus {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'assimilating';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'assimilationBusy';
      case ConnectFile3dStatus.Tiling:
        return 'assimilating';
      case ConnectFile3dStatus.IngestionError:
      case ConnectFile3dStatus.QuotaExceeded:
      case ConnectFile3dStatus.TilingError:
        return 'assimilationFailed';
      case ConnectFile3dStatus.Loading:
        return 'assimilating';
      case ConnectFile3dStatus.LoadingFailed:
        return 'loadingFailed';
      case ConnectFile3dStatus.Loaded:
        return 'loaded';
      case ConnectFile3dStatus.Unloading:
        return 'assimilating';
      case ConnectFile3dStatus.Unloaded:
        return 'unloaded';
    }
  }

  private getFileStatusMessage(status: ConnectFile3dStatus) {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'Checking status...';
      case ConnectFile3dStatus.IngestionError:
        return 'Error. Click to refresh.';
      case ConnectFile3dStatus.QuotaExceeded:
        return 'Quota will be exceeded.';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'Busy tiling. Click to refresh.';
      case ConnectFile3dStatus.Tiling:
        return 'Tiling...';
      case ConnectFile3dStatus.TilingError:
        return 'Tiling failed...';
      case ConnectFile3dStatus.Loading:
        return 'Loading...';
      case ConnectFile3dStatus.LoadingFailed:
        return '';
      case ConnectFile3dStatus.Loaded:
        return '';
      case ConnectFile3dStatus.Unloading:
        return 'Unloading...';
      case ConnectFile3dStatus.Unloaded:
        return undefined;
    }
  }

  private createIngestion(file: ConnectFile, downloadUrl: string) {
    return this.ingestionService.createIngestion(file, downloadUrl).pipe(
      map(() => file),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private checkQuota(file: ConnectFile) {
    return this.projectQuotaService
      .quotaExceeded(file.size ?? 0)
      .pipe(
        switchMap((quotaExceeded) =>
          quotaExceeded
            ? this.setup3dIcon(file.id, ConnectFile3dStatus.QuotaExceeded).pipe(
                switchMap(() => EMPTY),
              )
            : of(file),
        ),
      );
  }

  private setup3dIcon(connectFileId: string, status: ConnectFile3dStatus) {
    return from(
      this.workspace.api.ui.addCustomFileAction([
        {
          fileId: connectFileId,
          fileStatusIcon: {
            fileStatus: this.getFile3dStatus(status),
            fileStatusMessage: this.getFileStatusMessage(status),
          },
        },
      ]),
    );
  }

  private setup3dIconForScan(connectFileId: string, scan: ScandataModel) {
    switch (scan.status) {
      case PointcloudAPIStatus.Initializing:
      case PointcloudAPIStatus.InProgress:
      case PointcloudAPIStatus.Uploaded:
        return this.setup3dIcon(connectFileId, ConnectFile3dStatus.ReadyToRefresh);
      case PointcloudAPIStatus.Ready:
        return of(true); // Icon is setup in toggleScanDisplay
      case PointcloudAPIStatus.Failed:
        return this.setup3dIcon(connectFileId, ConnectFile3dStatus.TilingError);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(file: ConnectFile, err: any) {
    this.logger.error(`3d Ingesting error (${file.name})`, {}, err);
    return this.setup3dIcon(file.id, ConnectFile3dStatus.IngestionError).pipe(
      switchMap(() => EMPTY),
    );
  }

  private toggleScanDisplay(file: ConnectFile, scan: ScandataModel) {
    const status = !scan.showInScene ? ConnectFile3dStatus.Loading : ConnectFile3dStatus.Unloading;
    const action$ = !scan.showInScene
      ? this.host3dService.showScan(scan)
      : this.host3dService.hideScan(scan);
    return this.setup3dIcon(file.id, status).pipe(switchMap(() => action$));
  }

  private subscribeToDisplayedScans() {
    const firstDisplayStatus$ = this.getFirstStatusChangeForTiledScans(
      ScandataDisplayStatus.AwaitingDisplay,
    );

    const hasExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    const needsExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isNil(x.externalFileId))),
      filter((scans) => scans.length > 0),
      switchMap((scans) => forkJoin(scans.map((x) => this.scandataService.getScandataModel(x.id)))),
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    merge(hasExternalFileId$, needsExternalFileId$)
      .pipe(
        map((scans) => scans.map((s) => s.externalFileId)),
        filter((fileIds): fileIds is string[] => fileIds.length > 0),
        switchMap((fileIds) =>
          fileIds.map((id) => this.setup3dIcon(id, ConnectFile3dStatus.Loaded)),
        ),
      )
      .subscribe();
  }

  private subscribeToHiddenScans() {
    this.getFirstStatusChangeForTiledScans(ScandataDisplayStatus.Hidden)
      .pipe(
        map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
        map((scans) => scans.map((x) => x.externalFileId)),
        filter((fileIds): fileIds is string[] => fileIds.length > 0),
        switchMap((fileIds) =>
          fileIds.map((id) => this.setup3dIcon(id, ConnectFile3dStatus.Unloaded)),
        ),
      )
      .subscribe();
  }

  private getFirstStatusChangeForTiledScans(status: ScandataDisplayStatus) {
    const tiledScans$ = this.store.select(ScandataState.scandata).pipe(
      map((scans) => scans.filter((x) => x.status === PointcloudAPIStatus.Ready)),
      filter((scans) => scans.length > 0),
    );

    const firstStatus$ = tiledScans$.pipe(
      map((scans) => scans.filter((x) => x.displayStatus === status)),
      pairwise(),
      map(([prev, curr]) =>
        isNil(prev) ? curr : curr.filter((c) => isNil(prev.find((p) => p.id === c.id))),
      ),
    );

    return firstStatus$;
  }
}
