import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { isDefined } from '@trimble-gcs/common';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { ConnectFile } from 'trimble-connect-workspace-api';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { ConnectIngestion, ImportFile, ImportStatus } from './models/connect-ingestion';

@Injectable({
  providedIn: 'root',
})
export class ConnectIngestionService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private http: HttpClient,
    private scandataService: ScandataService,
  ) {}

  public createIngestion(file: ConnectFile, downloadUrl: string) {
    return this.getScan(file).pipe(
      switchMap((scan) => (isDefined(scan) ? of(scan) : this.createPointcloud(file.name, file.id))),
      switchMap((scan) => this.createImport(scan.id, scan.name, downloadUrl).pipe(map(() => scan))),
      switchMap(() => this.updateScanInStore(file)),
    );
  }

  public getIngestion(file: ConnectFile): Observable<ConnectIngestion> {
    return this.getScan(file).pipe(
      filter((scan): scan is ScandataModel => isDefined(scan)),
      map((scan) => ({ file, scan })),
    );
  }

  public getScan(file: ConnectFile) {
    return this.scandataService.getScanWithExternalFileId(file.id);
  }

  private createImport(pointcloudId: string, name: string, sourceUrl: string) {
    const importFile: ImportFile = {
      sourceUrl,
      targetFileName: name,
    };

    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}/imports`).pipe(
      switchMap((url) =>
        this.http.post<ImportStatus>(url, {
          files: [importFile],
        }),
      ),
    );
  }

  private createPointcloud(name: string, externalFileId: string) {
    return this.getScanProjectUrl$('/pointclouds').pipe(
      switchMap((url) =>
        this.http.post<ScandataModel>(url, {
          name,
          externalFileId,
        }),
      ),
    );
  }

  private updateScanInStore(file: ConnectFile) {
    // scandataService.getScanWithExternalFileId updates scan with
    // necessary properties and upserts into the store
    return this.getScan(file);
  }
}
