import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  inject,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  pipe,
  share,
  startWith,
  takeUntil,
} from 'rxjs';
import { ModusError } from '../directives/error.directive';
import { ModusHint } from '../directives/hint.directive';
import { ModusPrefix } from '../directives/prefix.directive';
import { ModusSuffix } from '../directives/suffix.directive';
import { ModusValid } from '../directives/valid.directive';
import { Nullable } from '../form-field-control/abstract-form-field-control';
import { FormFieldControl } from '../form-field-control/form-field-control.directive';

/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  selector: 'modus-form-field',
  exportAs: 'modusFormField',
  templateUrl: './form-field.component.html',
})
export class ModusFormField implements AfterContentInit, OnDestroy {
  private _element: HTMLElement;
  private _destroyed = new Subject<void>();

  @Input() hideRequiredMarker = false;

  @ContentChild(FormFieldControl) _formFieldControl!: FormFieldControl;
  @ContentChildren(ModusPrefix, { descendants: true }) _prefixChildren!: QueryList<ModusPrefix>;
  @ContentChildren(ModusSuffix, { descendants: true }) _suffixChildren!: QueryList<ModusSuffix>;

  @ContentChildren(ModusHint, { descendants: true }) _hintChildren!: QueryList<ModusHint>;
  @ContentChildren(ModusValid, { descendants: true }) _validChildren!: QueryList<ModusValid>;
  @ContentChildren(ModusError, { descendants: true }) _errorChildren!: QueryList<ModusError>;

  private get formFieldControl(): FormFieldControl {
    return this._formFieldControl;
  }

  private get ngControl(): Nullable<NgControl> {
    return this._formFieldControl.ngControl;
  }

  private get formControl(): Nullable<AbstractControl> {
    return this.ngControl?.control;
  }

  hasIconPrefix$!: Observable<boolean>;
  hasTextPrefix$!: Observable<boolean>;
  hasIconSuffix$!: Observable<boolean>;
  hasTextSuffix$!: Observable<boolean>;

  required$!: Observable<boolean>;
  disabled$!: Observable<boolean>;
  readonly$!: Observable<boolean>;
  focused$!: Observable<boolean>;

  untouched$!: Observable<boolean>;
  touched$!: Observable<boolean>;

  pristine$!: Observable<boolean>;
  dirty$!: Observable<boolean>;

  valid$!: Observable<boolean>;
  invalid$!: Observable<boolean>;

  showHint$!: Observable<boolean>;
  showValid$!: Observable<boolean>;
  showError$!: Observable<boolean>;

  constructor() {
    const elementRef = inject(ElementRef);
    this._element = elementRef.nativeElement;
    this._element.classList.add('modus-form-field');
  }

  async ngAfterContentInit() {
    this.assertFormControl();
    this.initializeControl();

    this.initializePrefixes();
    this.initializeSuffixes();

    this.initializeStateChanges();
    this.initializeSubscripts();
  }

  async ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private assertFormControl() {
    if (!this.formControl) {
      throw Error('modus-form-field must contain a FormControl.');
    }
  }

  private initializeControl() {
    this._element.classList.add(`modus-form-field-type-${this.formFieldControl.controlType}`);
  }

  private initializePrefixes() {
    this.hasIconPrefix$ = this._prefixChildren.changes.pipe(
      map(hasIcon),
      startWith(hasIcon(this._prefixChildren)),
      takeUntil(this._destroyed),
    );

    this.hasTextPrefix$ = this._prefixChildren.changes.pipe(
      map(hasText),
      startWith(hasText(this._prefixChildren)),
      takeUntil(this._destroyed),
    );
  }

  private initializeSuffixes() {
    this.hasIconSuffix$ = this._suffixChildren.changes.pipe(
      map(hasIcon),
      startWith(hasIcon(this._suffixChildren)),
      takeUntil(this._destroyed),
    );

    this.hasTextSuffix$ = this._suffixChildren.changes.pipe(
      map(hasText),
      startWith(hasText(this._suffixChildren)),
      takeUntil(this._destroyed),
    );
  }

  private initializeStateChanges() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const control = this.ngControl!.control!;
    const change$ = merge(this.formFieldControl.stateChanges, control.statusChanges).pipe(share());

    const pick = (mapFn: () => boolean) =>
      pipe(
        startWith(mapFn),
        map(mapFn),
        distinctUntilChanged(),
        share(),
        takeUntil(this._destroyed),
      );

    this.required$ = change$.pipe(pick(() => this.formFieldControl.required));
    this.disabled$ = change$.pipe(pick(() => this.formFieldControl.disabled));
    this.readonly$ = change$.pipe(pick(() => this.formFieldControl.readonly));

    this.focused$ = this.formFieldControl.stateChanges.pipe(
      filter((state) => state === 'focused'),
      map(() => this.formFieldControl.focused),
      takeUntil(this._destroyed),
    );

    this.untouched$ = change$.pipe(pick(() => control.untouched));
    this.touched$ = change$.pipe(pick(() => control.touched));

    this.pristine$ = change$.pipe(pick(() => control.pristine));
    this.dirty$ = change$.pipe(pick(() => control.dirty));

    this.valid$ = change$.pipe(pick(() => control.valid));
    this.invalid$ = change$.pipe(pick(() => control.invalid));
  }

  private initializeSubscripts() {
    const hasValid$ = this._validChildren.changes.pipe(
      map(hasElements),
      startWith(hasElements(this._validChildren)),
    );

    this.showValid$ = combineLatest([this.dirty$, this.valid$, hasValid$]).pipe(
      map(([dirty, valid, hasValid]) => dirty && valid && hasValid),
      takeUntil(this._destroyed),
    );

    const hasError$ = this._errorChildren.changes.pipe(
      map(hasElements),
      startWith(hasElements(this._errorChildren)),
    );

    this.showError$ = combineLatest([this.touched$, this.invalid$, hasError$]).pipe(
      map(([touched, invalid, hasError]) => touched && invalid && hasError),
      takeUntil(this._destroyed),
    );

    const hasHint$ = this._hintChildren.changes.pipe(
      map(hasElements),
      startWith(hasElements(this._hintChildren)),
    );

    this.showHint$ = combineLatest([hasHint$, this.showValid$, this.showError$]).pipe(
      map(([hasHint, showValid, showError]) => hasHint && !(showValid || showError)),
      takeUntil(this._destroyed),
    );
  }
}

function isIcon<T extends ModusPrefix | ModusSuffix>(x: T): boolean {
  return x.isIcon;
}

function isText<T extends ModusPrefix | ModusSuffix>(x: T): boolean {
  return x.isText;
}

function hasIcon<T extends ModusPrefix | ModusSuffix>(list: QueryList<T>) {
  return list.some(isIcon);
}

function hasText<T extends ModusPrefix | ModusSuffix>(list: QueryList<T>) {
  return list.some(isText);
}

function hasElements<T>(list: QueryList<T>) {
  return list.length > 0;
}
