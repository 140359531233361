<div class="flex flex-col h-full">
  <!--
  Use ngClass instead of ngSwitch to hide the list component.
  This prevents the component from being destroyed and
  loosing it's scroll position when other components are shown.
  -->
  <sd-scan-3d-list
    (exitStationClick)="onExitStationClick()"
    (settingsClick)="onSettingsClick()"
    (modelDetailClick)="onModelDetailClick($event)"
    class="flex flex-col h-full"
    [ngClass]="{ hidden: (view$ | async) !== view.List }"
  ></sd-scan-3d-list>

  @switch (view$ | async) {
    @case (view.Details) {
      <sd-scan-3d-detail
        (exitDetailClick)="onExitDetailClick()"
        class="flex flex-col h-full"
      ></sd-scan-3d-detail>
    }
    @case (view.Settings) {
      <sd-scan-3d-settings
        (exitSettingsClick)="onExitSettingsClick()"
        class="flex flex-col h-full"
      ></sd-scan-3d-settings>
    }
  }
</div>
