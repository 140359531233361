import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { ModusIconModule, ModusMenu, ModusMenuModule, ModusMenuTrigger } from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { CloseTreeMenu } from '../../scan-3d-panel/scan-3d.actions';
import { ScandataModel } from '../../scandata/scandata.models';

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree-menu',
  standalone: true,
  imports: [ModusIconModule, ModusMenuModule],
  templateUrl: './scandata-tree-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeMenuComponent {
  @ViewChild(ModusMenu) menu!: ModusMenu;
  @ViewChild(ModusMenuTrigger) menuTrigger!: ModusMenuTrigger;

  @Input() scan!: ScandataModel;
  @Input() fitToViewDisabled = false;

  @Output() modelDetailClick = new EventEmitter<ScandataModel>();
  @Output() modelFitToViewClick = new EventEmitter<ScandataModel>();

  constructor(private actions$: Actions) {
    this.subscribeToCloseTreeMenu();
  }

  onModelDetailClick() {
    this.modelDetailClick.emit(this.scan);
  }

  onModelFitToViewClick() {
    this.modelFitToViewClick.emit(this.scan);
  }

  private subscribeToCloseTreeMenu() {
    this.actions$
      .pipe(
        ofActionDispatched(CloseTreeMenu),
        filter(() => this.menu.isOpen),
        untilDestroyed(this),
      )
      .subscribe(() => this.menuTrigger.closeMenu());
  }
}
