import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { firstValueFrom, switchMap, tap } from 'rxjs';
import { ConnectService } from '../connect/connect.service';
import { GainsightService } from '../gainsight/gainsight.service';
import { Logger, injectLogger } from '../logging/logger';
import { FeatureLayerService } from '../map/feature-layer/feature-layer.service';
import { ProjectRegionService } from '../project-region/project-region.service';
import { SetIsLoading } from '../scandata/scandata.actions';
import { ScandataService } from '../scandata/scandata.service';
import { UserService } from '../services/user.service';
import { SetContext, SetSettings } from './app.actions';
import { AppContext, AppSettings } from './app.models';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private logger = injectLogger(Logger, 'AppService');

  constructor(
    private store: Store,
    private connectService: ConnectService,
    private gainsightService: GainsightService,
    private projectRegionService: ProjectRegionService,
    private userService: UserService,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
  ) {}

  async initialize(window: Window): Promise<void> {
    this.logger.debug(`AppInitialize started`);

    const settings = await this.initAppSettings();
    await this.initAppContext(window, settings);

    this.gainsightService.initialize(window, settings.gainsight.tag);

    this.logger.debug(`AppInitialize complete`);
  }

  async initAppSettings(): Promise<AppSettings> {
    const response: Response = await fetch('appsettings.json');
    const settings: AppSettings = await response.json();
    await firstValueFrom(this.store.dispatch(new SetSettings(settings)));

    this.logger.debug('AppSettings loaded', { settings });
    return settings;
  }

  async initAppContext(window: Window, settings: AppSettings): Promise<void> {
    const workspace = await this.connectService.getWorkspace(window.parent);
    const context = isDefined(workspace) ? AppContext.ConnectExtension : settings.appContext;
    await firstValueFrom(this.store.dispatch(new SetContext(context)));
  }

  loadAppData() {
    this.logger.debug(`loadAppData started`);

    return this.store.dispatch(new SetIsLoading(true)).pipe(
      switchMap(() => this.projectRegionService.loadProjectRegion()),
      switchMap(() => this.userService.loadUser()),
      switchMap(() => this.gainsightService.identifyUser()),
      switchMap(() => this.gainsightService.trackProject()),
      switchMap(() => this.gainsightService.detectGpu()),
      switchMap(() => this.scandataService.loadScandata()),
      switchMap(() => this.featureLayerService.loadFeatures()),
      tap(() => this.logger.debug(`loadAppData complete`)),
    );
  }
}
