import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { LoadingService } from '../../loading/loading.service';
import { PropertiesComponent } from '../../options-panel/selected-details/single-selected/properties/properties.component';

import { isDefined, isNil } from '@trimble-gcs/common';
import { PatchScandataModel } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataService } from '../../scandata/scandata.service';
import { Scan3dStyle } from '../models/scan-3d-style';
import { Scan3dStylingComponent } from '../scan-3d-styling/scan-3d-styling.component';
import { Scan3dService } from '../scan-3d.service';
import { Scan3dState } from '../scan-3d.state';
import { Scan3dClassificationComponent } from './scan-3d-classification/scan-3d-classification.component';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-detail',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    PropertiesComponent,
    Scan3dClassificationComponent,
    Scan3dStylingComponent,
    MatProgressBarModule,
    ModusTooltipModule,
  ],
  templateUrl: './scan-3d-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dDetailComponent {
  // This is picked and set in another component, then consumed here.
  // Because it crosses component bounds, it is kept in state.
  // We also need to load and patch model details, if it is not yet fully loaded.
  @Select(Scan3dState.current3dModel) currentModel$!: Observable<ScandataModel>;

  // Renamed, because this component does not know where we go or what we do when we leave here.
  // We simply want to emit the fact that the user wants to exit this component.
  // Its like clicking the 'Close' button in Windows; the window does not know what happens next.
  @Output() exitDetailClick = new EventEmitter<void>();

  showProgressBar$ = this.loadingService.isLoading$(this);
  scan3dStyle$!: Observable<Scan3dStyle>;

  constructor(
    private loadingService: LoadingService,
    private scandataService: ScandataService,
    private store: Store,
    private scan3dService: Scan3dService,
  ) {
    this.loadScan3dStyle();
    this.loadScandataModel();
  }

  onExitDetailClick() {
    this.exitDetailClick.emit();
  }

  onStyleChange(style: Scan3dStyle) {
    const scan = this.store.selectSnapshot(Scan3dState.current3dModel);
    if (isNil(scan)) return;

    const currentScan3dStyle =
      scan.scan3dStyle ?? this.store.selectSnapshot(Scan3dState.globalStyle);

    // Turn eye dome lighting on when classification color is switched on
    const classificationShowing = currentScan3dStyle?.showClassification === true;
    if (
      classificationShowing === false &&
      style.showClassification === true &&
      style.showEyeDomeLighting === false
    ) {
      style.showEyeDomeLighting = true;
    }

    scan.scan3dStyle = style;
    this.store.dispatch(new PatchScandataModel(scan));
  }

  private loadScan3dStyle() {
    this.scan3dStyle$ = this.currentModel$.pipe(
      filter(isDefined),
      switchMap((scan) => {
        if (scan.scan3dStyle) return of(scan.scan3dStyle);

        return this.getCopyOfGlobalStyle();
      }),
    );
  }

  private getCopyOfGlobalStyle() {
    return this.scan3dService.getGlobalStyle().pipe(
      map((globalStyle) => {
        const globalStyleCopy: Scan3dStyle = {
          ...globalStyle,
          classificationSchemes: globalStyle.classificationSchemes.map((scheme) => ({
            ...scheme,
          })),
        };

        return globalStyleCopy;
      }),
    );
  }

  private loadScandataModel() {
    // Composed a single observable to load the model and patch the scandata and current model state.
    // FullyLoaded should stop this subscription from looping
    this.currentModel$
      .pipe(
        filter((model) => model && !model.fullyLoaded),
        switchMap((model) =>
          this.loadingService.loadFrom<ScandataModel>(
            this.scandataService.getScandataModel(model.id),
            this,
          ),
        ),
        switchMap((model) => this.store.dispatch([new PatchScandataModel(model)])),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
