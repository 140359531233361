import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { combineLatest, filter, map, of, switchMap, take } from 'rxjs';
import { SetConnectRegion, SetConnectRegions } from '../app-state/app.actions';
import { AppState } from '../app-state/app.state';
import { ConnectRegion, ConnectRegionDto } from './connect.models';

@Injectable({
  providedIn: 'root',
})
export class ConnectRegionService {
  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  getConnectRegionEndpoint() {
    return this.store.selectOnce(AppState.connectRegion).pipe(
      switchMap((connectRegion) => {
        if (isDefined(connectRegion)) return of(connectRegion);

        return this.getAndCacheConnectRegion();
      }),
      map((connectRegion) => connectRegion.endpoint),
    );
  }

  private getAndCacheConnectRegion() {
    return combineLatest([this.store.select(AppState.project), this.getConnectRegions()]).pipe(
      filter(([project]) => isDefined(project)),
      map(([project, regions]) => regions.find((region) => region.location === project.location)),
      filter(isDefined),
      take(1),
      switchMap((region) => {
        return this.store.dispatch(new SetConnectRegion(region)).pipe(map(() => region));
      }),
    );
  }

  private getConnectRegions() {
    return this.store.selectOnce(AppState.connectRegions).pipe(
      switchMap((regions) => {
        if (isDefined(regions)) return of(regions);

        return this.getAndCacheConnectRegions();
      }),
    );
  }

  private getAndCacheConnectRegions() {
    return this.store.select(AppState.settings).pipe(
      switchMap((settings) => {
        const baseUrl = settings.endpoints.connect.url;
        const url = buildUrl(baseUrl, '/regions');
        return this.http.get<ConnectRegionDto[]>(url);
      }),
      map((regions) => {
        return regions.map((region) => {
          const connectRegion: ConnectRegion = {
            ...region,
            endpoint: {
              url: region['tc-api'],
              protected: true,
            },
          };
          return connectRegion;
        });
      }),
      switchMap((regions) => {
        return this.store.dispatch(new SetConnectRegions(regions)).pipe(map(() => regions));
      }),
    );
  }
}
