<div class="flex justify-center items-center w-full h-full">
  @if (treeIcon === treeIconType.Loading) {
    <div class="flex justify-center items-center w-full h-full">
      <mat-spinner [diameter]="18" [strokeWidth]="2"></mat-spinner>
    </div>
  } @else {
    <div
      class="flex justify-center items-center cursor-pointer min-w-[1.75rem] h-full text-gray-8"
      (click)="onTreeIconClick()"
      [ngClass]="{ 'cursor-default': disabled }"
    >
      @switch (treeIcon) {
        @case (treeIconType.On) {
          <i
            class="flex justify-center items-center text-xl tc-icon-eye-visibility"
            [ngClass]="{ 'opacity-30': disabled }"
          ></i>
        }
        @case (treeIconType.Off) {
          <i
            class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-download"
            [ngClass]="{ 'opacity-30': disabled }"
          ></i>
        }
        @case (treeIconType.Unavailable) {
          <i
            class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-error"
            [ngClass]="{ 'opacity-30': disabled }"
          ></i>
        }
        @case (treeIconType.Error) {
          <i
            class="flex justify-center items-center text-xl tc-icon-eye-visibility-off-error text-red"
          ></i>
        }
      }
    </div>
  }
</div>
