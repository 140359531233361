import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  Observable,
  combineLatest,
  combineLatestWith,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { ErrorState } from '../../error-handling/error.state';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from '../../scandata-list/scandata-empty/scandata-empty.component';
import { ScandataTreeViewComponent } from '../../scandata-tree/scandata-tree-view/scandata-tree-view.component';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { ClearCurrentStation } from '../../station/station.actions';
import { CurrentStation, StationDisplayStatus } from '../../station/station.models';
import { StationState } from '../../station/station.state';
import { Scan3dListFilterOption } from '../models/scan-3d-list-filter-option';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-list',
  standalone: true,
  imports: [
    CommonModule,
    ModusFormFieldModule,
    ModusSelectModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ScandataTreeViewComponent,
    ScandataEmptyComponent,
    ScrollingModule,
    MatSelectModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dListComponent {
  // This data crosses component bounds and is kept in state.
  // It is set up at the entry point into the 3D extension and consumed here.
  @Select(ScandataState.selected) selected$!: Observable<ScandataModel[]>;
  @Select(ScandataState.scandata) scandata$!: Observable<ScandataModel[]>;
  @Select(ScandataState.isLoading) isLoading$!: Observable<boolean>;
  @Select(ErrorState.hasError('scanLoadError')) scanLoadError$!: Observable<boolean>;
  @Select(StationState.currentStation) currentStation$!: Observable<CurrentStation>;

  @Output() settingsClick = new EventEmitter<void>();
  @Output() modelDetailClick = new EventEmitter<ScandataModel>();

  filterOptionControl = new FormControl<Scan3dListFilterOption>(Scan3dListFilterOption.ShowAll);
  scan3dListFilterOption = Scan3dListFilterOption;

  data$!: Observable<ScandataModel[]>;
  hasCurrentStation$!: Observable<boolean>;
  currentStationName$!: Observable<string>;
  showScandataEmpty$!: Observable<boolean>;
  scandataEmptyReason$!: Observable<ScandataEmptyReason>;

  constructor(private store: Store) {
    this.setupForm();
    this.setupCurrentStation();
    this.setupScandataEmpty();
  }

  onModelDetailClick(model: ScandataModel) {
    this.modelDetailClick.emit(model);
  }

  onExitStationClick() {
    this.store.dispatch(new ClearCurrentStation());
  }

  private setupForm() {
    this.selected$
      .pipe(
        take(1),
        map((selected) => {
          return selected.length > 0
            ? Scan3dListFilterOption.ShowSelected
            : Scan3dListFilterOption.ShowAll;
        }),
        untilDestroyed(this),
      )
      .subscribe((option) => {
        return this.filterOptionControl.setValue(option);
      });

    this.data$ = this.filterOptionControl.valueChanges.pipe(
      startWith(this.filterOptionControl.value),
      switchMap((option) => {
        return option === Scan3dListFilterOption.ShowAll ? this.scandata$ : this.selected$;
      }),
      map((scandata) => scandata.sort((a, b) => a.name.localeCompare(b.name))),
      shareReplay(),
      untilDestroyed(this),
    );
  }

  private setupCurrentStation() {
    this.hasCurrentStation$ = this.currentStation$.pipe(
      map((current) => {
        return isDefined(current) && current.displayStatus === StationDisplayStatus.Displayed;
      }),
    );

    this.currentStationName$ = this.currentStation$.pipe(
      map((current) => {
        return current?.station?.name ?? '';
      }),
    );
  }

  private setupScandataEmpty() {
    this.showScandataEmpty$ = combineLatest([
      this.isLoading$,
      this.scanLoadError$,
      this.data$,
    ]).pipe(
      map(([loading, error, scandata]) => {
        if (loading || error) return false;
        return scandata.length === 0;
      }),
    );

    this.scandataEmptyReason$ = this.showScandataEmpty$.pipe(
      filter((empty) => empty),
      combineLatestWith(this.scandata$),
      map(([, scandata]) => {
        if (scandata.length === 0) {
          return ScandataEmptyReason.NoUploads;
        }

        if (this.filterOptionControl.value === Scan3dListFilterOption.ShowSelected) {
          return ScandataEmptyReason.NoSelected;
        }

        return ScandataEmptyReason.NoUploads;
      }),
    );
  }
}
