import { AppRoute } from '../../app.routes';

export interface TabLink {
  title: string;
  route: AppRoute;
  icon: string;
}

export const tabLinks: TabLink[] = [
  {
    title: 'Map',
    route: AppRoute.MapView,
    icon: 'map',
  },
  {
    title: 'List',
    route: AppRoute.ListView,
    icon: 'bookings',
  },
];
