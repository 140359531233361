<table
  mat-table
  matSort
  [dataSource]="dataSource"
  [trackBy]="getScanId"
  (matSortChange)="onSortChange($event)"
  class="select-none"
>
  <!-- selected column -->
  <ng-container matColumnDef="selected">
    <th mat-header-cell *matHeaderCellDef>
      <modus-checkbox
        id="list-toggle-check-all"
        [checked]="isAllSelected()"
        [indeterminate]="isSomeSelected()"
        (checkboxClick)="toggleAllRows()"
        (keydown.space)="$event.preventDefault()"
      >
      </modus-checkbox>
    </th>
    <td mat-cell *matCellDef="let row; let rowIndex = index" class="w-4">
      <modus-checkbox
        id="list-toggle-check"
        [checked]="row.selected"
        (keydown.enter)="checkboxKeyDown(row)"
        (keydown.space)="checkboxKeyDown(row); $event.preventDefault()"
      ></modus-checkbox>
    </td>
  </ng-container>

  <!-- thumbnailUrl column -->
  <ng-container matColumnDef="thumbnailUrl">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="!py-1">
      <div
        class="min-h-[72px] h-[72px] min-w-[120px] w-[120px] rounded-lg border border-gray-2 flex items-center justify-center"
      >
        @if (isScanReady(row)) {
          @if (scanHasPreview(row)) {
            <img [src]="row.thumbnailUrl" class="max-w-full max-h-full object-contain" />
          }
          @if (!scanHasPreview(row) && scanHasStation(row)) {
            <img src="../../../assets/image_scene_lightgrey_24dp.svg" class="h-[55%] w-[55%]" />
          }
        } @else {
          <img src="../../../assets/hourglass_lightgrey_24dp.svg" class="h-[50%] w-[50%]" />
        }
      </div>
    </td>
  </ng-container>

  <!-- name column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="min-w-[228px]">Name</th>
    <td mat-cell *matCellDef="let row">
      <span class="line-clamp-3 break-anywhere">
        {{ row.name }}
      </span>
    </td>
  </ng-container>

  <!-- status column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
    <td mat-cell *matCellDef="let row">
      <md-icon [modus-tooltip]="getIcon(row).message" [ngStyle]="{ color: getIcon(row).color }">
        {{ getIcon(row).icon }}</md-icon
      >
    </td>
  </ng-container>

  <!-- uploadedBy column -->
  <ng-container matColumnDef="uploadedBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="uploadedBy" class="whitespace-nowrap">
      Uploaded By
    </th>
    <td mat-cell *matCellDef="let row">{{ row.uploadedBy }}</td>
  </ng-container>

  <!-- uploadedDate column -->
  <ng-container matColumnDef="uploadedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="uploadedDate" class="whitespace-nowrap">
      Upload Date
    </th>
    <td mat-cell *matCellDef="let row">
      <span class="block whitespace-nowrap">{{ row.uploadedDate | date: 'mediumDate' }}</span>
      <span class="block whitespace-nowrap">{{ row.uploadedDate | date: 'shortTime' }}</span>
    </td>
  </ng-container>

  <!-- pointCount column -->
  <ng-container matColumnDef="pointCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="pointCount">Points</th>
    <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
      {{ row.pointCount | pointCount: 3 }}
    </td>
  </ng-container>

  <!-- fileSizeGB column -->
  <ng-container matColumnDef="fileSizeGB">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="fileSizeGB">Size</th>
    <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
      {{ row.fileSizeGB > 0 ? (row.fileSizeGB | fileSize: 1) : '' }}
    </td>
  </ng-container>

  <!-- tags column -->
  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef>Tags</th>
    <td mat-cell *matCellDef="let row" class="min-w-52 max-w-64">
      <sd-chip-container
        [chips]="row.tags"
        (chipClick)="onChipClick($event, row)"
        class="h-20"
      ></sd-chip-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
    (click)="rowClicked($event, row, rowIndex)"
    [ngClass]="{ '!bg-blue-pale': row.selected }"
  ></tr>
</table>
