import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ScandataModel } from '../scandata/scandata.models';
import { ClearCurrentStation } from '../station/station.actions';
import { Scan3dDetailComponent } from './scan-3d-detail/scan-3d-detail.component';
import { Scan3dListComponent } from './scan-3d-list/scan-3d-list.component';
import { Scan3dSettingsComponent } from './scan-3d-settings/scan-3d-settings.component';
import { ClearCurrent3dModel, SetCurrent3dModel, SetScan3dView } from './scan-3d.actions';
import { Scan3dState, Scan3dView } from './scan-3d.state';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-panel',
  standalone: true,
  imports: [CommonModule, Scan3dListComponent, Scan3dDetailComponent, Scan3dSettingsComponent],
  templateUrl: './scan-3d-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dPanelComponent {
  // This component has the following responsibiliites:
  // - decide which of 3 components to display
  // - react to events emitted by components and update state, to be consumed in other components.

  @Select(Scan3dState.view) view$!: Observable<Scan3dView>;

  view = Scan3dView;

  constructor(private store: Store) {}

  onExitStationClick() {
    this.store.dispatch(new ClearCurrentStation());
  }

  onSettingsClick() {
    this.store.dispatch(new SetScan3dView(Scan3dView.Settings));
  }

  onExitSettingsClick() {
    this.store.dispatch(new SetScan3dView(Scan3dView.List));
  }

  onModelDetailClick(model: ScandataModel) {
    this.store.dispatch([new SetCurrent3dModel(model), new SetScan3dView(Scan3dView.Details)]);
  }

  onExitDetailClick() {
    this.store.dispatch([new ClearCurrent3dModel(), new SetScan3dView(Scan3dView.List)]);
  }
}
