import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';

export interface ProjectQuota {
  unit: string;
  quota: number;
  quotaUsedByAccount: number;
  quotaUsedByProject: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectQuotaService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(private http: HttpClient) {}

  getProjectQuota(): Observable<ProjectQuota> {
    return this.getScanProjectUrl$('/quota').pipe(
      switchMap((url) => this.http.get<ProjectQuota>(url)),
    );
  }

  quotaExceeded(addBytes: number = 0) {
    // Quota is in GB
    return this.getProjectQuota().pipe(
      map(
        (projectQuota) =>
          projectQuota.quotaUsedByAccount + addBytes * 1024 ** -3 >= projectQuota.quota,
      ),
    );
  }
}
