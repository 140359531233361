import { ScandataModel } from '../scandata/scandata.models';
import { Scan3dStyle } from './models/scan-3d-style';
import { Scan3dView } from './scan-3d.state';

export class SetScan3dView {
  static readonly type = '[Scan3d] SetScan3dView';
  constructor(public readonly view: Scan3dView) {}
}

export class SetCurrent3dModel {
  static readonly type = '[Scan3d] SetCurrent3dModel';
  constructor(public readonly scandataModel: ScandataModel) {}
}

export class UpdateCurrent3dModel {
  static readonly type = '[Scan3d] UpdateCurrent3dModel';
  constructor(public readonly scandataModel: Partial<ScandataModel>) {}
}

export class ClearCurrent3dModel {
  static readonly type = '[Scan3d] ClearCurrent3dModel';
}

export class SetGlobalStyle {
  static readonly type = '[Scan3d] SetGlobalStyle';
  constructor(public readonly style: Scan3dStyle) {}
}

export class CloseTreeMenu {
  static readonly type = '[Scan3d] CloseTreeMenu';
}
