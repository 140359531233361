<div class="h-full overflow-y-auto" cdkScrollable>
  @if (isLoading$ | async) {
    <div class="sticky top-0 left-0 right-0 z-[200]">
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    </div>
  }

  @if (scanLoadError$ | async) {
    <div class="p-4 pb-0">
      <modus-alert
        class="w-full"
        message="Error loading scan data. Please try again later."
        type="error"
      ></modus-alert>
    </div>
  }

  <div class="min-w-full w-fit p-4">
    <div class="flex flex-auto flex-col bg-white shadow-md">
      <sd-scandata-table
        [data]="filteredScandata$ | async"
        [sorting]="(sorting$ | async)!"
        [selectedIds]="selectedIds$ | async"
        (selectionChange)="selectionChange($event)"
        (chipClick)="chipClick($event)"
        (sortingChange)="sortingChange($event)"
      ></sd-scandata-table>

      @if (showScandataEmpty$ | async) {
        <sd-scandata-empty [reason]="scandataEmptyReason$ | async"></sd-scandata-empty>
      }
    </div>
  </div>
</div>
