import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@trimble-gcs/common';

@Pipe({
  name: 'dashedIfEmpty',
  standalone: true,
})
export class DashedIfEmptyPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (isNil(value) || value.length === 0) {
      return '--';
    }

    return value;
  }
}
