<sd-scandata-tree
  [scandata]="scandata"
  [currentStation]="(currentStation$ | async)!"
  [cameraProjection]="(cameraProjection$ | async)!"
  (modelIconClick)="modelIconClick($event)"
  (modelNameClick)="modelNameClick($event)"
  (modelExpandClick)="toggleExpand($event)"
  (modelDetailClick)="modelDetailClicked($event)"
  (modelFitToViewClick)="modelFitToViewClicked($event)"
  (stationIconClick)="stationIconClick($event)"
  (stationNameClick)="stationNameClick($event)"
></sd-scandata-tree>
