import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { switchMap } from 'rxjs';
import { AppRoute } from '../../app.routes';
import { Logger, injectLogger } from '../../logging/logger';
import { SetReturnPath } from '../auth.actions';
import { AuthService } from '../auth.service';
import { AuthState } from '../auth.state';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, MatProgressSpinnerModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private logger = injectLogger(Logger, 'LoginComponent');

  busy = signal(true);
  error = signal(false);

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (isNil(code)) {
      const state = this.location.getState() as { returnPath: string };
      const returnPath = state.returnPath ?? '';

      this.logger.debug('isNil(code) ~ location.getState()', state);
      this.logger.debug('isNil(code) ~ returnPath', { returnPath });

      // store the returnPath now, in case we're redirected to TID
      // if so, we need to retrieve it once redirected back to our app
      this.store
        .dispatch(new SetReturnPath(returnPath))
        .pipe(
          switchMap(() => {
            this.logger.debug('isNil(code) ~ authService.signIn()');
            return this.authService.signIn();
          }),
          switchMap(() => {
            this.logger.debug("isNil(code) ~ SetReturnPath('')");
            return this.store.dispatch(new SetReturnPath(''));
          }),
          untilDestroyed(this),
        )
        .subscribe({
          next: () => {
            this.logger.debug(`isNil(code) ~ navigateByUrl(${returnPath})`);
            return this.router.navigateByUrl(returnPath);
          },
          error: (err) => {
            this.logger.error('isNil(code) ~ Error', {}, err);
            return this.handleError();
          },
        });
    }

    if (isDefined(code)) {
      const returnPath = this.store.selectSnapshot(AuthState.returnPath) ?? '';
      this.logger.debug('isDefined(code) ~ returnPath', { returnPath });

      this.authService
        .codeTokenExchange(code)
        .pipe(
          switchMap((token) => {
            this.logger.debug(`isDefined(code) ~ getUserInfo(token.id_token)`, {
              id_token: token.id_token,
            });
            return this.authService.getUserInfo(token.id_token);
          }),
          switchMap(() => {
            this.logger.debug("isDefined(code) ~ SetReturnPath('')");
            return this.store.dispatch(new SetReturnPath(''));
          }),
          untilDestroyed(this),
        )
        .subscribe({
          next: () => {
            history.replaceState(null, '', AppRoute.Login);
            this.logger.debug(`isDefined(code) ~ navigateByUrl(${returnPath})`);
            this.router.navigateByUrl(returnPath);
          },
          error: (err) => {
            this.logger.error('isDefined(code) ~ Error', {}, err);
            return this.handleError();
          },
        });
    }
  }

  private handleError() {
    history.replaceState(null, '', AppRoute.Login);
    this.busy.set(false);
    this.error.set(true);
  }
}
