import { Injectable } from '@angular/core';
import { NgxsAfterBootstrap, Select, Selector, State, StateContext } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { Observable } from 'rxjs';
import { ScandataModel } from './scandata.models';
import { ScandataState } from './scandata.state';

export interface ScandataSelectedStateModel {
  ids: string[];
}

const defaultState: ScandataSelectedStateModel = {
  ids: [],
};

@State<ScandataSelectedStateModel>({
  name: 'scandataSelectedState',
  defaults: defaultState,
})
@Injectable()
export class ScandataSelectedState implements NgxsAfterBootstrap {
  @Select(ScandataState.selected) selectedScans$!: Observable<ScandataModel[]>;

  @Selector() static ids(state: ScandataSelectedStateModel): string[] {
    return state.ids;
  }

  ngxsAfterBootstrap(ctx: StateContext<ScandataSelectedStateModel>): void {
    this.selectedScans$.subscribe((scans) => {
      const currentIds = ctx.getState().ids;

      //remove id's no longer selected
      const ids = currentIds.filter((id) => isDefined(scans.find((scan) => scan.id === id)));

      //add new id's selected
      const addIds = scans
        .filter((scan) => isNil(ids.find((id) => id === scan.id)))
        .map((scan) => scan.id);

      const selectedIds = [...ids, ...addIds];

      ctx.patchState({ ids: selectedIds });
    });
  }
}
