<div class="flex flex-col bg-white h-full">
  <div class="w-full relative">
    @if (isLoading) {
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    }
  </div>
  <div class="flex flex-col px-3 py-2 border-b border-gray-1">
    <div class="text-lg font-medium">Scan Data</div>
    <mat-select
      id="map-list-filter"
      class="modus-dropdown"
      panelClass="modus-dropdown-panel"
      [formControl]="filterOptionControl"
      disableRipple
    >
      <mat-option [value]="mapFilterOption.All">All Scan Data</mat-option>
      <mat-option [value]="mapFilterOption.LocatedOnly">Located Scan Data Only</mat-option>
    </mat-select>
  </div>

  @if (scanLoadError) {
    <modus-alert
      class="w-full block p-4"
      message="Error loading scan data. Please try again later."
      type="error"
    ></modus-alert>
  }

  <div class="overflow-y-auto pt-1 pb-3" cdkScrollable>
    @for (item of mapScandata; track item.id) {
      <div
        class="flex flex-row items-center cursor-pointer hover:bg-gray-0 py-0.5 pl-[0.4rem] pr-2"
        (click)="itemClicked.emit(item)"
      >
        <modus-checkbox
          id="map-list-toggle-check"
          class="scale-[0.85]"
          [checked]="item.selected"
          (checkboxClick)="itemChecked.emit(item)"
          (click)="$event.stopPropagation()"
        >
        </modus-checkbox>
        <div modus-tooltip="{{ item.name }}" class="w-full overflow-hidden ml-[0.2rem] text-sm">
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
            [ngClass]="{ 'text-red': scanHasError(item), 'text-gray-6': scanNotReady(item) }"
          >
            {{ item.name }}
          </div>
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-small italic text-gray-6"
          >
            {{ getScanErrorMessage(item) }}
          </div>
        </div>
        <button
          id="map-list-toggle-visible"
          modus-icon-button
          class="min-w-[1.75rem] text-gray-8 disabled:text-gray-6"
          [disabled]="!item.hasFeature"
          (click)="changeItemVisibilityClicked.emit(item); $event.stopPropagation()"
        >
          @if (item.hiddenOnMap) {
            <md-icon class="!text-xl">visibility_off</md-icon>
          } @else {
            <md-icon class="!text-xl">visibility_on</md-icon>
          }
        </button>
        <button
          id="map-list-goto"
          modus-icon-button
          class="min-w-[1.75rem] text-gray-8 disabled:text-gray-6"
          [disabled]="!item.hasFeature"
          (click)="zoomToItemClicked.emit(item); $event.stopPropagation()"
        >
          <md-icon class="!text-xl">location</md-icon>
        </button>
      </div>
    }
    @if (showScandataEmpty) {
      <sd-scandata-empty [reason]="scandataEmptyReason"></sd-scandata-empty>
    }
  </div>
</div>
