import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { isNil } from '@trimble-gcs/common';
import { Observable } from 'rxjs';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { Scan3dStyle } from './models/scan-3d-style';
import {
  ClearCurrent3dModel,
  SetCurrent3dModel,
  SetGlobalStyle,
  SetScan3dView,
  UpdateCurrent3dModel,
} from './scan-3d.actions';

export enum Scan3dView {
  List = 'List',
  Details = 'Details',
  Settings = 'Settings',
}

export interface Scan3dStateModel {
  view: Scan3dView;
  globalStyle?: Scan3dStyle;
  current3dModel?: ScandataModel;
}

const defaultState: Scan3dStateModel = {
  view: Scan3dView.List,
};

@State<Scan3dStateModel>({
  name: 'scan3dState',
  defaults: defaultState,
})
@Injectable()
export class Scan3dState implements NgxsAfterBootstrap {
  @Select(ScandataState.scandata) scans$!: Observable<ScandataModel[]>;

  @Selector() static view(state: Scan3dStateModel) {
    return state.view;
  }

  @Selector() static globalStyle(state: Scan3dStateModel): Scan3dStyle | undefined {
    return state.globalStyle;
  }

  @Selector() static current3dModel(state: Scan3dStateModel): ScandataModel | undefined {
    return state.current3dModel;
  }

  ngxsAfterBootstrap(ctx: StateContext<Scan3dStateModel>): void {
    this.scans$.subscribe((scans) => {
      const current3dModel = ctx.getState().current3dModel;
      if (isNil(current3dModel)) return;

      const scandataModel = scans.find((item) => item.id === current3dModel.id);
      if (isNil(scandataModel)) return;

      const updateScan = { ...current3dModel, ...scandataModel };
      ctx.patchState({ current3dModel: updateScan });
    });
  }

  @Action(SetScan3dView) setScan3dView(
    ctx: StateContext<Scan3dStateModel>,
    { view }: SetScan3dView,
  ) {
    ctx.patchState({ view });
  }

  @Action(SetCurrent3dModel) setCurrent3dModel(
    ctx: StateContext<Scan3dStateModel>,
    { scandataModel }: SetCurrent3dModel,
  ) {
    ctx.patchState({ current3dModel: scandataModel });
  }

  @Action(UpdateCurrent3dModel) updateCurrent3dScandata(
    ctx: StateContext<Scan3dStateModel>,
    { scandataModel }: UpdateCurrent3dModel,
  ) {
    ctx.setState(patch({ current3dModel: patch({ ...scandataModel }) }));
  }

  @Action(ClearCurrent3dModel) clearCurrent3dScandata(ctx: StateContext<Scan3dStateModel>) {
    ctx.patchState({ current3dModel: undefined });
  }

  @Action(SetGlobalStyle) setGlobalStyle(
    ctx: StateContext<Scan3dStateModel>,
    { style }: SetGlobalStyle,
  ) {
    ctx.patchState({ globalStyle: style });
  }
}
