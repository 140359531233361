import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { filter, map, switchMap } from 'rxjs';
import {
  ConnectFile,
  EventId,
  EventToArgMap,
  WorkspaceAPI,
  connect,
} from 'trimble-connect-workspace-api';
import { SetProject } from '../app-state/app.actions';
import { ClearAuth, SetConnectToken } from '../auth/auth.actions';
import { FitToView, SetHost3dScandata } from '../connect-3d-ext/host-3d.actions';
import { ScandataState } from '../scandata/scandata.state';
import { ConnectWorkspace } from './connect.models';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectFileDownload } from './models/connect-file-download';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);
  private _workspace!: ConnectWorkspace;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actions$: Actions,
  ) {}

  async getWorkspace(
    target?: Window | HTMLIFrameElement,
    timeout: number | undefined = 1000 * 30,
  ): Promise<ConnectWorkspace> {
    if (isNil(this._workspace) && isDefined(target)) {
      await this.initWorkspace(target, timeout);
    }
    return this._workspace;
  }

  goTo3dExtension() {
    this.store
      .selectOnce(ScandataState.scandata)
      .pipe(switchMap((scandata) => this.store.dispatch(new SetHost3dScandata(scandata))))
      .subscribe(async () => await this._workspace.api.extension.goTo('3dviewer'));
  }

  getFileDownloadUrl(file: ConnectFile) {
    const versionQuery = file.versionId ? `?versionId=${file.versionId}` : '';
    return this.getConnectRegionUrl$(`files/fs/${file.id}/downloadurl${versionQuery}`).pipe(
      switchMap((url) => this.http.get<ConnectFileDownload>(url)),
    );
  }

  private async initWorkspace(target: Window | HTMLIFrameElement, timeout?: number | undefined) {
    const api = await this.createWorkspace(target, timeout);

    if (isWorkspaceAPI(api)) {
      this._workspace = new ConnectWorkspace(api);

      await this.getConnectProject();
      this.subscribeToConnectToken();
      await this.requestConnectToken();
      this.subscribeToFitToView();
    }
  }

  private async createWorkspace(
    target: Window | HTMLIFrameElement,
    timeout?: number | undefined,
  ): Promise<WorkspaceAPI> {
    const onEvent = this.onWorkspaceEvent.bind(this);
    return await connect(target, onEvent, timeout);
  }

  private onWorkspaceEvent<T extends EventId, U extends EventToArgMap[T]>(eventId: T, arg: U) {
    const data = arg?.data;
    const action = arg?.action;
    const event = { id: eventId, data, action };
    this._workspace?.['_event$'].next(event);
  }

  private async getConnectProject() {
    const project = await this._workspace.api.project.getProject();
    this.store.dispatch(new SetProject(project));
    return project;
  }

  private subscribeToConnectToken() {
    this._workspace.event$.pipe(filter((event) => event.id === 'extension.accessToken')).subscribe({
      next: (event) => {
        const connectToken = event.data as string;
        this.store.dispatch(new SetConnectToken(connectToken));
      },
    });
  }

  private async requestConnectToken() {
    const token = await this._workspace.api.extension.requestPermission('accesstoken');
    if (token.toLowerCase() === 'pending') {
      this.store.dispatch(new ClearAuth());
    } else {
      this.store.dispatch(new SetConnectToken(token));
    }
  }

  private subscribeToFitToView() {
    this.actions$
      .pipe(
        ofActionDispatched(FitToView),
        map((action) => action.models.map((x) => ({ modelId: x.web3dId, objectRuntimeIds: [1] }))),
      )
      .subscribe((modelObjectIds) => this._workspace.api.viewer.setCamera({ modelObjectIds }));
  }
}

export function isWorkspaceAPI(arg: unknown): arg is WorkspaceAPI {
  return (arg as WorkspaceAPI).extension !== undefined;
}
