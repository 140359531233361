<!--single selection-->
@if (showSingleSelected$ | async) {
  <sd-single-selected
    [scandataModel]="(singleSelected$ | async)!"
    [readonly]="(readonly$ | async)!"
    (downloadClicked)="showDownload(true)"
  ></sd-single-selected>
}

<!--multi selection-->
@if (showMultiSelected$ | async) {
  <sd-multi-selected
    [scandataModels]="(multiSelected$ | async)!"
    [readonly]="(readonly$ | async)!"
    (downloadClicked)="showDownload(true)"
    (unselectClicked)="unselectScan($event)"
    (unselectAllClicked)="unselectAllScans()"
  ></sd-multi-selected>
}

<!--download-->
@if (showDownload$ | async) {
  <sd-download-select (closeClicked)="showDownload(false)"></sd-download-select>
}
