import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusAutocomplete,
  ModusAutocompleteModule,
  ModusButtonModule,
  ModusIconModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  combineLatestWith,
  filter,
  from,
  map,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ConnectCommand } from '../../../../connect/connect.models';
import { ConnectService } from '../../../../connect/connect.service';
import { DialogData } from '../../../../dialog/dialog.model';
import { DialogService } from '../../../../dialog/dialog.service';
import { ScandataModel, UpdateScandataModel } from '../../../../scandata/scandata.models';
import { ScandataService } from '../../../../scandata/scandata.service';
import { MAX_TAG_LENGTH, TagService } from '../../../../tag/tag.service';
import { NotWhitespaceStringValidator } from '../../../../utils/not-whitespace-string-validator';

interface CurrentTag {
  text: string;
  onAllScans: boolean;
  remove: boolean;
}

@UntilDestroy()
@Component({
  selector: 'sd-tagging',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    ModusAutocompleteModule,
    MatProgressBarModule,
    ScrollingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tagging.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'flex flex-col h-full';

  @Input() set scandataModels(value: ScandataModel[]) {
    this.scans$.next(value);
  }

  @Output() closeClicked = new EventEmitter();

  @ViewChild('autocomplete') autocomplete!: ModusAutocomplete<string>;

  filteredTags$!: Observable<string[]>;
  saveDisabled$!: Observable<boolean>;
  errorText$!: Observable<string>;
  currentTags$!: Observable<CurrentTag[]>;
  hasCurrentTags$!: Observable<boolean>;

  scans$ = new BehaviorSubject<ScandataModel[]>([]);
  removeTags$ = new BehaviorSubject<string[]>([]);
  addTags$ = new BehaviorSubject<string[]>([]);
  isSaving$ = new BehaviorSubject(false);

  tagSelector = new FormControl<string | null>(null, {
    validators: [NotWhitespaceStringValidator, Validators.maxLength(MAX_TAG_LENGTH)],
    updateOn: 'change',
  });

  private projectTags$!: Observable<string[]>;

  constructor(
    private connectService: ConnectService,
    private tagService: TagService,
    private scandataService: ScandataService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.currentTags$ = this.getCurrentTags();
    this.projectTags$ = this.getProjectTags();
    this.filteredTags$ = this.getFilteredTags();

    this.hasCurrentTags$ = this.currentTags$.pipe(map((tags) => tags.some((tag) => !tag.remove)));

    this.saveDisabled$ = combineLatest([this.isSaving$, this.addTags$, this.currentTags$]).pipe(
      map(([isSaving, addTags, currentTags]) => {
        return isSaving || (addTags.length === 0 && currentTags.every((tag) => !tag.remove));
      }),
    );

    this.errorText$ = this.tagSelector.statusChanges.pipe(
      tap(() => {
        if (this.tagSelector.invalid && this.tagSelector.dirty) {
          this.tagSelector.markAsTouched();
        }
      }),
      map(() => {
        return this.tagSelector.hasError('maxlength')
          ? `Maximum ${MAX_TAG_LENGTH} characters allowed.`
          : '';
      }),
    );
  }

  ngAfterViewInit() {
    this.autocomplete.inputKeydown$
      .pipe(
        filter((event) => event.key === 'Enter'),
        filter(() => {
          const listSelection = isDefined(this.autocomplete.activeOption);
          if (listSelection) return false;

          const inputInvalid = this.tagSelector.pristine || this.tagSelector.invalid;
          if (inputInvalid) return false;

          const value = this.tagSelector.getRawValue() as string;
          const duplicateInput = this.addTags$.value.find(
            (addTag) => addTag.toLowerCase() === value.toLowerCase(),
          )
            ? true
            : false;
          if (duplicateInput) return false;

          return true;
        }),
        switchMap(() => this.projectTags$.pipe(take(1))),
        map((tags) => {
          const value = this.tagSelector.getRawValue() as string;
          const tag = tags.find((tag) => tag.toLowerCase() === value.toLowerCase()) ?? value;

          return tag;
        }),
        untilDestroyed(this),
      )
      .subscribe((tag) => {
        this.addTag(tag);
        this.autocomplete.closePanel();
      });
  }

  remove(tag: string) {
    const addTags = this.addTags$.value;
    const index = addTags.indexOf(tag);

    if (index >= 0) {
      addTags.splice(index, 1);
      this.addTags$.next(addTags);
    }
  }

  removeCurrent(removeTag: CurrentTag) {
    const removeTags = [...this.removeTags$.value, removeTag.text];
    this.removeTags$.next(removeTags);
  }

  addTag(tag: string) {
    if (this.tagSelector.invalid || isNil(tag)) return;

    const addTags = [...this.addTags$.value, tag];

    this.addTags$.next(addTags);
    this.tagSelector.reset();
  }

  save() {
    this.setFormSaving(true);

    const updates$ = combineLatest([this.scans$, this.addTags$, this.removeTags$]).pipe(
      map(([scans, addTags, removeTags]) => {
        const updates = scans.map((scan) => {
          const tags = (scan.tags ?? []).filter((tag) => {
            return !removeTags.includes(tag);
          });
          const uniqueTags = [...new Set([...tags, ...addTags])];

          return <UpdateScandataModel & { pointcloudId: string }>{
            pointcloudId: scan.id,
            tags: uniqueTags,
          };
        });
        return updates;
      }),
    );

    return updates$
      .pipe(
        take(1),
        switchMap((updates) => this.scandataService.updateScandataModels(updates)),
        combineLatestWith(this.scans$),
        take(1),
        map(([result, scans]) => {
          const errors = scans.filter((model) => {
            const hasError = result.some(
              (update) =>
                update.updateScandataModel.pointcloudId === model.id && isDefined(update.error),
            );
            return hasError;
          });

          if (errors.length === 0) return this.closeClicked.emit();

          this.showSaveErrors(errors);
          this.setFormSaving(false);
        }),
      )
      .subscribe();
  }

  private getCurrentTags() {
    return combineLatest([this.scans$, this.removeTags$]).pipe(
      map(([scans, removeTags]) => {
        const tags = scans.flatMap((scan) => scan.tags).filter(isDefined);
        const uniqueTags = [...new Set(tags)];

        return uniqueTags.map(
          (tag) =>
            ({
              text: tag,
              onAllScans: scans.every((model) => model.tags?.includes(tag)),
              remove: removeTags.includes(tag),
            }) satisfies CurrentTag,
        );
      }),
    );
  }

  private setFormSaving(isSaving: boolean) {
    if (isSaving) {
      this.tagSelector.disable({ emitEvent: false });
    } else {
      this.tagSelector.enable({ emitEvent: false });
    }

    this.isSaving$.next(isSaving);
  }

  private getProjectTags() {
    const nothing = '';

    return from(this.connectService.getWorkspace()).pipe(
      switchMap((ws) => {
        return isDefined(ws)
          ? ws.command$.pipe(
              filter((event) => event.data === ConnectCommand.ScandataBrowser),
              map(() => nothing),
              startWith(nothing),
            )
          : of(nothing);
      }),
      switchMap(() => this.tagService.getTags()),
    );
  }

  private getFilteredTags() {
    return this.tagSelector.valueChanges.pipe(
      startWith(null),
      combineLatestWith(this.projectTags$, this.addTags$, this.currentTags$),
      map(([filter, tags, addTags, currentTags]) => {
        const availableTags = tags
          .filter((tag) => !addTags.includes(tag)) // exclude tags already in add list
          .filter((tag) => {
            // exclude tags that are already on all selected scans and not marked for removal
            return !currentTags.some(
              (currentTag) =>
                currentTag.text === tag && currentTag.onAllScans && !currentTag.remove,
            );
          });

        if (isNil(filter)) return availableTags;

        const filterValue = filter.toLowerCase();
        return availableTags.filter((tag) => tag.toLowerCase().includes(filterValue));
      }),
    );
  }

  private showSaveErrors(failedModels: ScandataModel[]) {
    const message =
      `The following scan${failedModels.length > 1 ? 's' : ''} failed to update:\n\n` +
      failedModels.map((model) => model.name).join('\n');

    const dialogData = new DialogData('Save Error', message, {
      text: 'Dismiss',
      color: 'danger',
    });

    this.dialogService.show(dialogData).pipe(untilDestroyed(this)).subscribe();
  }
}
