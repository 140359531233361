import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { appRoutes } from './app.routes';
import { AuthState } from './auth/auth.state';
import { tokenInterceptor } from './auth/token.interceptor';
import { ClassificationSchemeState } from './classification/classification-scheme.state';
import { ConfigState } from './config/config.state';
import { CameraState } from './connect-3d-ext/camera/camera.state';
import { Host3dState } from './connect-3d-ext/host-3d.state';
import { ErrorState } from './error-handling/error.state';
import { ConsoleLogger } from './logging/console-logger';
import { DataDogLogger } from './logging/data-dog-logger';
import { Logger } from './logging/logger';
import { LOGGERS } from './logging/logger-types';
import { loggingInterceptor } from './logging/logging.interceptor';
import { BaseLayerState } from './map/base-layer/base-layer.state';
import { FeatureLayerState } from './map/feature-layer/feature-layer.state';
import { MapState } from './map/map.state';
import { DownloadParametersState } from './options-panel/download/download-parameters.state';
import { DownloadState } from './options-panel/download/download.state';
import { OptionsPanelState } from './options-panel/options-panel.state';
import { RouteState } from './route/route.state';
import { Scan3dState } from './scan-3d-panel/scan-3d.state';
import { ScandataSelectedState } from './scandata/scandata-selected.state';
import { ScandataState } from './scandata/scandata.state';
import { ScannerTypeState } from './scanner-type/scanner-type.state';
import { WINDOW, windowProvider } from './services/window.provider';
import { StationState } from './station/station.state';
import { TagState } from './tag/tag.state';
import { TilesetState } from './tileset/tileset.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor, loggingInterceptor])),
    importProvidersFrom(
      MatDialogModule,
      NgxsModule.forRoot(
        [
          AppState,
          AuthState,
          ScandataState,
          OptionsPanelState,
          BaseLayerState,
          FeatureLayerState,
          TilesetState,
          StationState,
          ClassificationSchemeState,
          ConfigState,
          Host3dState,
          Scan3dState,
          TagState,
          ScannerTypeState,
          MapState,
          DownloadParametersState,
          DownloadState,
          ErrorState,
          ScandataSelectedState,
          CameraState,
          RouteState,
        ],
        {
          selectorOptions: {
            // These Selector Settings are recommended in preparation for NGXS v4
            // (See https://www.ngxs.io/concepts/select#joining-selectors for their effects)
            suppressErrors: false,
            injectContainerState: false,
          },
        },
      ),
      NgxsStoragePluginModule.forRoot({
        key: [AuthState, Host3dState, RouteState],
      }),
    ),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService, WINDOW],
      multi: true,
    },
    { provide: Logger, useClass: Logger },
    { provide: LOGGERS, useClass: ConsoleLogger, multi: true },
    { provide: LOGGERS, useClass: DataDogLogger, multi: true },
  ],
};

function initializeAppFactory(appService: AppService, window: Window) {
  return () => appService.initialize(window);
}
