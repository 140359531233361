import { Route } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { NoProjectAccessComponent } from './auth/no-project-access/no-project-access.component';
import { ConfigComponent } from './config/config.component';
import { Host3dComponent } from './connect-3d-ext/host-3d.component';
import { DialogDemoComponent } from './dialog/demo/dialog-demo.component';
import { EasterEggComponent } from './easter-egg/easter-egg.component';
import { TabHostComponent } from './hosts/tab-host/tab-host.component';
import { LoadingDemoComponent } from './loading/loading-demo/loading-demo.component';
import { MapComponent } from './map/map.component';
import { redirectToLastVisitedRoute } from './route/redirect-to-last-visited-route.guard';
import { ScandataListComponent } from './scandata-list/scandata-list.component';

export const enum AppRoute {
  Login = 'login',
  Logout = 'logout',
  MapView = 'map-view',
  ListView = 'list-view',
  Config = 'config',
  Connect3d = 'connect-3d',
  NoProjectAccess = 'no-project-access',
}

export const appRoutes: Route[] = [
  { path: AppRoute.Login, component: LoginComponent },
  { path: AppRoute.Logout, component: LogoutComponent },
  { path: AppRoute.Config, component: ConfigComponent, canActivate: [authGuard] },
  { path: 'easter-egg/:image', component: EasterEggComponent, canActivate: [authGuard] },
  { path: 'loading-demo', component: LoadingDemoComponent },
  { path: 'dialog-demo', component: DialogDemoComponent },
  { path: AppRoute.Connect3d, component: Host3dComponent },
  { path: AppRoute.NoProjectAccess, component: NoProjectAccessComponent },
  {
    path: '',
    component: TabHostComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [redirectToLastVisitedRoute],
        children: [],
      },
      { path: AppRoute.MapView, component: MapComponent, canActivate: [authGuard] },
      { path: AppRoute.ListView, component: ScandataListComponent, canActivate: [authGuard] },
    ],
  },
  { path: '**', redirectTo: '' },
];
