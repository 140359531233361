import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Select } from '@ngxs/store';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { Observable } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../../app-state/app.state';
import { User } from '../../auth/auth.models';

@Component({
  selector: 'sd-app-bar',
  standalone: true,
  imports: [CommonModule, ModusButtonModule, ModusIconModule, MatToolbarModule, MatMenuModule],
  templateUrl: './app-bar.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {
  @Input() user?: User;
  @Input() loggedIn = false;

  @Output() menuClick = new EventEmitter<MouseEvent>();
  @Output() profileClick = new EventEmitter<void>();
  @Output() licenceClick = new EventEmitter<void>();
  @Output() logoutClick = new EventEmitter<void>();

  @Select(AppState.project) project$!: Observable<ConnectProject>;
}
