import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { ClassificationComponent } from './classification/classification.component';
import { SetActiveConfigTab } from './config.actions';
import { ConfigState, ConfigTab } from './config.state';
import { StorageComponent } from './storage/storage.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatTabsModule, StorageComponent, ClassificationComponent],
  templateUrl: './config.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigComponent implements OnInit {
  @Select(ConfigState.activeTab) private activeTab$!: Observable<ConfigTab>;

  selectedTabIndex$!: Observable<number>;

  private tabs = {
    [ConfigTab.Storage]: 0,
    [ConfigTab.Classification]: 1,
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedTabIndex$ = this.activeTab$.pipe(map((activeTab) => this.tabs[activeTab]));
  }

  tabChange(event: MatTabChangeEvent) {
    const tab = Object.keys(this.tabs)[event.index] as ConfigTab;
    this.store.dispatch(new SetActiveConfigTab(tab));
  }
}
