@if (downloadScans$ | async; as scans) {
  <div class="flex flex-col max-w-full text-sm">
    <div class="h-7 flex flex-row items-center mb-3">
      <div class="w-full text-sm font-bold">Package Contents</div>
      <!-- all download and progress -->
      @if (showDownloadAll$ | async) {
        <div class="min-w-[32px] max-w-[32px] ml-2 flex justify-center">
          @if (allFilesDownloading$ | async) {
            <sd-download-progress
              [downloadFiles]="filesDownloading$ | async"
              [progressTooltipPrefix]="'Overall Progress'"
            ></sd-download-progress>
          } @else {
            <button
              id="options-panel-download-all"
              modus-icon-button
              modus-tooltip="Download All Files"
              class="mt-1"
              (click)="downloadAll()"
            >
              <md-icon>download</md-icon>
            </button>
          }
        </div>
      }
    </div>
    <div>
      <!-- list scandata model -->
      @for (scan of scans; track $index) {
        <div>
          <div class="flex flex-row items-center">
            <button modus-icon-button (click)="toggleExpanded(scan)">
              @if (scan.expanded) {
                <md-icon class="text-lg/5">caret_down</md-icon>
              } @else {
                <md-icon class="text-lg/5">caret_right</md-icon>
              }
            </button>
            <md-icon class="-mt-1">file</md-icon>
            <div
              modus-tooltip="{{ scan.scandataModel.name }}"
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-2"
            >
              {{ scan.scandataModel.name }}
            </div>
            <!-- scandata model download and progress -->
            <div class="min-w-[32px] max-w-[32px] ml-2 flex justify-center">
              @if (allScanFilesDownloading(scan)) {
                <sd-download-progress
                  [downloadFiles]="scan.files"
                  [progressTooltipPrefix]="'Scan Progress'"
                ></sd-download-progress>
              } @else if (scanHasFiles(scan)) {
                <button
                  id="options-panel-download-scan"
                  modus-icon-button
                  modus-tooltip="Download Scan Files"
                  class="-mt-1"
                  (click)="downloadScan(scan)"
                >
                  <md-icon>download</md-icon>
                </button>
              }
            </div>
          </div>
          <!-- list scandata model files -->
          @if (scan.expanded) {
            <div class="pl-[3.25rem] mb-2">
              @if (scanHasFiles(scan)) {
                <div>
                  @for (file of scan.files; track file.pointcloudId) {
                    <div class="group flex flex-row items-center">
                      <md-icon class="-mt-1">{{ getIcon(file.scandataFile.filename) }}</md-icon>
                      <div
                        modus-tooltip="{{ file.scandataFile.filename }}"
                        class="w-full overflow-hidden text-ellipsis whitespace-nowrap leading-8 ml-2"
                      >
                        {{ file.scandataFile.filename }}
                      </div>
                      <!-- file download and progress -->
                      <div class="min-w-[32px] max-w-[32px] ml-2 flex justify-center">
                        @if (file.downloadProgress) {
                          <sd-download-progress
                            [downloadFiles]="[file]"
                            [progressTooltipPrefix]="'File Progress'"
                          ></sd-download-progress>
                        } @else {
                          <button
                            id="options-panel-download-file"
                            modus-icon-buttonl
                            modus-tooltip="Download"
                            class="-mt-1"
                            (click)="downloadFile(scan, file)"
                          >
                            <md-icon>download</md-icon>
                          </button>
                        }
                      </div>
                    </div>
                  }
                </div>
              } @else {
                <div class="flex flex-row items-center">
                  <md-icon class="-mt-1">alert_outlined</md-icon>
                  <div class="leading-8 ml-2">No files available</div>
                </div>
              }
              <!-- no model files -->
            </div>
          }
        </div>
      }
    </div>
  </div>
}
