import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, combineLatest, distinctUntilChanged, map, switchMap, take } from 'rxjs';
import { ScandataModel } from '../../../../scandata/scandata.models';
import { ScandataState } from '../../../../scandata/scandata.state';
import { DownloadProgressComponent } from '../../download-progress/download-progress.component';
import { AddToDownload } from '../../download.actions';
import { DownloadFile, DownloadScan } from '../../download.model';
import { DownloadState, downloadsChanged } from '../../download.state';

@UntilDestroy()
@Component({
  selector: 'sd-download-select-list',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    DownloadProgressComponent,
  ],
  templateUrl: './download-select-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSelectListComponent implements OnInit {
  @Select(DownloadState.downloads) private currentDownloads$!: Observable<DownloadScan[]>;
  @Select(ScandataState.selected) private selectedScandataModels$!: Observable<ScandataModel[]>;

  downloadScans$!: Observable<DownloadScan[]>;

  showDownloadAll$!: Observable<boolean>;
  allFilesDownloading$!: Observable<boolean>;
  filesDownloading$!: Observable<DownloadFile[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.downloadScans$ = this.getDownloadScans();

    this.showDownloadAll$ = this.downloadScans$.pipe(
      map((scans) => scans.length > 1 && scans.some((scan) => scan.files.length > 0)),
    );

    this.allFilesDownloading$ = this.downloadScans$.pipe(
      map((scans) => {
        return scans.every((scan) => scan.files.every((file) => isDefined(file.downloadProgress)));
      }),
    );

    this.filesDownloading$ = this.downloadScans$.pipe(
      map((scans) => {
        return scans.flatMap((scan) =>
          scan.files.filter((file) => isDefined(file.downloadProgress)),
        );
      }),
    );
  }

  getIcon(filename: string) {
    const extension = filename.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image';
      default:
        return 'file';
    }
  }

  scanHasFiles(scan: DownloadScan) {
    return scan.files.length > 0;
  }

  allScanFilesDownloading(scan: DownloadScan) {
    return scan.files.length > 0 && scan.files.every((file) => isDefined(file.downloadProgress));
  }

  toggleExpanded(item: DownloadScan) {
    item.expanded = !item.expanded;
  }

  downloadAll() {
    this.downloadScans$
      .pipe(
        take(1),
        switchMap((scans) => {
          const actions = scans.flatMap((scan) => {
            return scan.files.map((file) => new AddToDownload(scan, file));
          });

          return this.store.dispatch(actions);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  downloadScan(scan: DownloadScan) {
    const actions = scan.files.map((file) => new AddToDownload(scan, file));
    this.store.dispatch(actions);
  }

  downloadFile(scan: DownloadScan, file: DownloadFile) {
    this.store.dispatch(new AddToDownload(scan, file));
  }

  private getDownloadScans(): Observable<DownloadScan[]> {
    return this.getSelectedMergedWithDownloads().pipe(
      map((scans) => {
        scans.forEach((scan) => {
          scan.files.sort((a, b) => a.scandataFile.filename.localeCompare(b.scandataFile.filename));
        });

        return scans.sort((a, b) => a.scandataModel.name.localeCompare(b.scandataModel.name));
      }),
    );
  }

  private getSelectedMergedWithDownloads(): Observable<DownloadScan[]> {
    const currentDownloads$ = this.currentDownloads$.pipe(distinctUntilChanged(downloadsChanged));

    return combineLatest([this.selectedScandataModels$, currentDownloads$]).pipe(
      map(([selected, currentDownloads]) => {
        const merged = selected.map((scandataModel) => {
          const downloadScan: DownloadScan = currentDownloads.find(
            (scan) => scan.scandataModel.id === scandataModel.id,
          ) ?? {
            scandataModel,
            expanded: true,
            files: [],
          };

          const downloadFiles = scandataModel.files.map((scandataFile) => {
            const downloadFile: DownloadFile = downloadScan.files.find(
              (file) => file.scandataFile.filename === scandataFile.filename,
            ) ?? {
              pointcloudId: downloadScan.scandataModel.id,
              scandataFile,
            };

            return downloadFile;
          });

          return { ...downloadScan, ...{ files: downloadFiles } };
        });

        return merged;
      }),
    );
  }
}
