import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, distinctUntilChanged, filter, map, take } from 'rxjs';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { SetView } from '../../options-panel.actions';
import { OptionsPanelView } from '../../options-panel.state';
import { DownloadProgressComponent } from '../download-progress/download-progress.component';
import { ClearCompletedDownloads } from '../download.actions';
import { DownloadFile, DownloadScan, DownloadStatus } from '../download.model';
import { DownloadService } from '../download.service';
import { DownloadState, downloadsChanged } from '../download.state';

@UntilDestroy()
@Component({
  selector: 'sd-download-status',
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    DownloadProgressComponent,
  ],
  templateUrl: './download-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadStatusComponent {
  @Select(DownloadState.downloads) private currentDownloads$!: Observable<DownloadScan[]>;
  @Select(DownloadState.activeDownloadCount) private activeDownloadCount$!: Observable<number>;

  downloadScans$!: Observable<DownloadScan[]>;

  hasDownloads$!: Observable<boolean>;
  filesDownloading$!: Observable<DownloadFile[]>;

  clearDisabled$!: Observable<boolean>;
  cancelDisabled$!: Observable<boolean>;

  constructor(
    private downloadService: DownloadService,
    private store: Store,
    private dialogService: DialogService,
  ) {
    this.downloadScans$ = this.getDownloadScans();

    this.hasDownloads$ = this.downloadScans$.pipe(map((downloads) => downloads.length > 0));

    this.filesDownloading$ = this.downloadScans$.pipe(
      map((scans) => {
        return scans.flatMap((scan) =>
          scan.files.filter((file) => isDefined(file.downloadProgress)),
        );
      }),
    );

    this.clearDisabled$ = this.currentDownloads$.pipe(
      map((scans) => {
        const files = scans.flatMap((scan) => scan.files);
        const enableClear = files.some((file) => {
          const status = file.downloadProgress?.status;
          return status === DownloadStatus.Complete || status === DownloadStatus.Failed;
        });
        return !enableClear;
      }),
    );

    this.cancelDisabled$ = this.activeDownloadCount$.pipe(
      map((downloadCount) => downloadCount === 0),
    );
  }

  close() {
    this.store.dispatch(new SetView(OptionsPanelView.None, false));
  }

  toggleExpanded(item: DownloadScan) {
    item.expanded = !item.expanded;
  }

  getIcon(filename: string) {
    const extension = filename.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image';
      default:
        return 'file';
    }
  }

  clearCompleted() {
    this.store.dispatch(new ClearCompletedDownloads());
  }

  cancelDownloads() {
    this.showCancelConfirmation()
      .pipe(take(1))
      .subscribe(() => this.downloadService.cancelAllDownloads());
  }

  private getDownloadScans(): Observable<DownloadScan[]> {
    return this.currentDownloads$.pipe(
      distinctUntilChanged(downloadsChanged),
      map((scans) => {
        scans.forEach((scan) => {
          scan.files.sort((a, b) => a.scandataFile.filename.localeCompare(b.scandataFile.filename));
        });

        return scans.sort((a, b) => a.scandataModel.name.localeCompare(b.scandataModel.name));
      }),
    );
  }

  private showCancelConfirmation() {
    const dialogData = new DialogData(
      'Cancel Downloads',
      `Are you sure you want to cancel all downloads?`,
      { text: 'Yes', color: 'danger' },
      CANCEL_BUTTON,
    );

    return this.dialogService.show(dialogData).pipe(filter((confirmed) => confirmed));
  }
}
