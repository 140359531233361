import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { CameraProjection } from '../../connect-3d-ext/camera/camera.models';
import {
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import {
  CurrentStation,
  Station,
  StationDisplayStatus,
  StationLoadStatus,
} from '../../station/station.models';
import {
  ScandataTreeIconComponent,
  TreeIcon,
} from '../scandata-tree-icon/scandata-tree-icon.component';
import { ScandataTreeMenuComponent } from '../scandata-tree-menu/scandata-tree-menu.component';

@Component({
  selector: 'sd-scandata-tree',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusButtonModule,
    ModusTooltipModule,
    MatProgressSpinnerModule,
    ScandataTreeIconComponent,
    ScandataTreeMenuComponent,
  ],
  templateUrl: './scandata-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeComponent {
  @Input() scandata!: ScandataModel[];
  @Input() currentStation?: CurrentStation;
  @Input() cameraProjection!: CameraProjection;

  @Output() modelIconClick = new EventEmitter<ScandataModel>();
  @Output() modelNameClick = new EventEmitter<ScandataModel>();
  @Output() modelDetailClick = new EventEmitter<ScandataModel>();
  @Output() modelExpandClick = new EventEmitter<ScandataModel>();
  @Output() modelFitToViewClick = new EventEmitter<ScandataModel>();

  @Output() stationIconClick = new EventEmitter<Station>();
  @Output() stationNameClick = new EventEmitter<Station>();
  @Output() stationMoreClick = new EventEmitter<Station>();

  modelIconClicked(model: ScandataModel) {
    this.modelIconClick.emit(model);
  }

  modelNameClicked(model: ScandataModel) {
    this.modelNameClick.emit(model);
  }

  modelDetailClicked(model: ScandataModel) {
    this.modelDetailClick.emit(model);
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.modelFitToViewClick.emit(model);
  }

  stationIconClicked(station: Station) {
    this.stationIconClick.emit(station);
  }

  stationNameClicked(station: Station) {
    if (this.loadStationDisabled()) return;

    this.stationNameClick.emit(station);
  }

  stationMoreClicked(station: Station) {
    this.stationMoreClick.emit(station);
  }

  toggleExpand(model: ScandataModel) {
    this.modelExpandClick.emit(model);
  }

  stationsLoading(model: ScandataModel) {
    return model.stationLoadStatus === StationLoadStatus.Loading;
  }

  fitToViewDisabled(model: ScandataModel) {
    return (
      model.displayStatus !== ScandataDisplayStatus.Displayed || isDefined(this.currentStation)
    );
  }

  loadModelDisabled(model: ScandataModel) {
    return model.pointCount === 0 || model.pointcloudStatus !== PointcloudStatus.Ready;
  }

  modelNotReady(model: ScandataModel) {
    return model.pointcloudStatus === PointcloudStatus.Processing;
  }

  modelHasError(model: ScandataModel) {
    return this.getModelIcon(model) === TreeIcon.Error;
  }

  getModelInfoMessage(model: ScandataModel) {
    if (model.pointcloudStatus === PointcloudStatus.Processing) {
      return 'Not ready for viewing';
    }

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return 'Failed to ingest';
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return 'Failed to load';
    }

    if (model.pointCount === 0) {
      return 'Stations only';
    }

    return null;
  }

  getModelIcon(model: ScandataModel): TreeIcon {
    if (model.pointcloudStatus === PointcloudStatus.Processing) {
      return TreeIcon.Unavailable;
    }

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.Loading ||
      model.displayStatus === ScandataDisplayStatus.AwaitingDisplay
    ) {
      return TreeIcon.Loading;
    }

    if (!model.showInScene) {
      return TreeIcon.Off;
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return TreeIcon.Error;
    }

    return TreeIcon.On;
  }

  stationHasError(station: Station) {
    return this.getStationIcon(station) === TreeIcon.Error;
  }

  getStationErrorMessage(station: Station) {
    if (this.getStationIcon(station) === TreeIcon.Error) {
      return 'Failed to load';
    }

    return null;
  }

  getStationIcon(station: Station): TreeIcon {
    const viewed = station.id === this.currentStation?.station.id;
    if (viewed) {
      if (this.currentStation?.displayStatus === StationDisplayStatus.AwaitingDisplay) {
        return TreeIcon.Loading;
      }
      if (this.currentStation?.displayStatus === StationDisplayStatus.DisplayError) {
        return TreeIcon.Error;
      }
      if (this.currentStation?.displayStatus === StationDisplayStatus.Displayed) {
        return TreeIcon.On;
      }

      return TreeIcon.Off;
    } else {
      return TreeIcon.Off;
    }
  }

  loadStationDisabled() {
    return (
      this.currentStation?.displayStatus === StationDisplayStatus.AwaitingDisplay ||
      this.cameraProjection === CameraProjection.Orthographic
    );
  }
}
