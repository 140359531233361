import { ExtensionMainMenu } from 'trimble-connect-workspace-api';
import { AppSettings } from '../../app-state/app.models';
import { ConnectCommand } from '../../connect/connect.models';

export function createGeospatialMenu(settings: AppSettings): ExtensionMainMenu {
  return {
    title: settings.title,
    icon: `${settings.baseUrl}/assets/public_white_24dp.svg`,
    command: ConnectCommand.ScandataBrowser,
  };
}
