<div class="flex flex-col h-full">
  <div class="flex flex-row items-center py-2 pl-3 text-xl font-medium border-b border-gray-1">
    Global Settings
    <div class="flex flex-1 justify-end mr-2">
      <button
        id="3d-global-settings-close"
        class="text-gray-8"
        modus-icon-button
        (click)="exitSettingsClick.emit()"
      >
        <md-icon>close</md-icon>
      </button>
    </div>
  </div>

  <mat-tab-group preserveContent="true" class="mds-small h-full overflow-hidden" disableRipple>
    <mat-tab label="Classification" labelClass="!p-0">
      <div class="h-full overflow-y-auto border-t border-gray-1 p-4">
        <sd-scan-3d-classification
          class="h-full"
          [scan3dStyle]="(scan3dStyle$ | async)!"
          (styleChange)="onStyleChange($event)"
        ></sd-scan-3d-classification>
      </div>
    </mat-tab>
    <mat-tab label="Styling" labelClass="!p-0">
      <div class="h-full border-t border-gray-1 p-4">
        <sd-scan-3d-styling
          class="h-full"
          [scan3dStyle]="(scan3dStyle$ | async)!"
          (styleChange)="onStyleChange($event)"
        ></sd-scan-3d-styling>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
