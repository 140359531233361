import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  Observable,
  Subject,
  catchError,
  forkJoin,
  map,
  of,
  retry,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogData } from '../dialog/dialog.model';
import { DialogService } from '../dialog/dialog.service';
import { HANDLE_ERROR } from '../error-handling/error-handler-operator';
import { ClearError } from '../error-handling/error.actions';
import { LoadingService } from '../loading/loading.service';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { Filters, Paging, Sorting } from './scandata-query.models';
import {
  AddScan,
  ClearScandata,
  PatchScandataModel,
  PatchScandataModels,
  RemoveScandataModel,
  SetIsLoading,
  SetScandata,
  UpdateScandata,
} from './scandata.actions';
import {
  PointcloudAPIStatus,
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataModel,
  UpdateScandataModel,
} from './scandata.models';
import { ScandataState } from './scandata.state';

@Injectable({
  providedIn: 'root',
})
export class ScandataService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private readonly handleError = inject(HANDLE_ERROR);

  private readonly statusMap = new Map<PointcloudAPIStatus, PointcloudStatus>([
    [PointcloudAPIStatus.Initializing, PointcloudStatus.Processing],
    [PointcloudAPIStatus.Uploaded, PointcloudStatus.Processing],
    [PointcloudAPIStatus.InProgress, PointcloudStatus.Processing],
    [PointcloudAPIStatus.Ready, PointcloudStatus.Ready],
    [PointcloudAPIStatus.Failed, PointcloudStatus.Failed],
  ]);

  private readonly cancelGetScandata = new Subject<void>();

  constructor(
    private store: Store,
    private http: HttpClient,
    private loadingService: LoadingService,
    private dialogService: DialogService,
  ) {
    this.loadingService
      .isLoading$(this)
      .pipe(tap((loading) => this.store.dispatch(new SetIsLoading(loading))))
      .subscribe();
  }

  setDisplayStatus(scandata: ScandataModel[], displayStatus: ScandataDisplayStatus) {
    if (scandata.length === 0) {
      return;
    }

    const patchModels: Partial<ScandataModel>[] = scandata.map((item) => ({
      id: item.id,
      displayStatus,
    }));

    this.store.dispatch(new PatchScandataModels(patchModels));
  }

  loadScandata() {
    return this.getScandata().pipe(
      switchMap((data) => {
        /**
         * This could complete after scans have been set in the store by the 3D host,
         * therefore it dispatches UpdateScandata, and not SetScandata.
         */
        return this.store.dispatch(new UpdateScandata(data));
      }),
    );
  }

  refreshScandata() {
    return this.getScandata().pipe(
      switchMap((data) => {
        return this.store.dispatch(new SetScandata(data));
      }),
    );
  }

  getScandataModel(pointcloudId: string): Observable<ScandataModel> {
    return this.store.selectOnce(ScandataState.scandata).pipe(
      map((models) => {
        return models.find((model) => model.id == pointcloudId && model.fullyLoaded);
      }),
      switchMap((model) =>
        isDefined(model)
          ? of(model)
          : this.getAndCacheScandataModel(pointcloudId).pipe(
              this.handleError('scanDetailsLoadError'),
            ),
      ),
    );
  }

  getScanWithExternalFileId(externalFileId: string) {
    return this.getScanProjectUrl$(`/pointclouds?filterBy=externalFileId=${externalFileId}`).pipe(
      switchMap((url) => this.http.get<ScandataModel[]>(url)),
      switchMap((scans) =>
        scans.length > 0
          ? this.cacheScandataModel({ ...this.mapScandataModel(scans[0]), fullyLoaded: true })
          : of(undefined),
      ),
    );
  }

  updateScandataModel(
    pointcloudId: string,
    updateScandataModel: UpdateScandataModel,
  ): Observable<ScandataModel> {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => {
        return this.http.patch<ScandataModel>(url, updateScandataModel);
      }),
      map((updatedModel) => this.mapScandataModel(updatedModel)),
      switchMap((updatedModel) => {
        const patchModel = {
          ...updatedModel,
          ...{
            captureDatetimeUtc: updatedModel.captureDatetimeUtc,
            scannerType: updatedModel.scannerType,
            notes: updatedModel.notes,
          },
        };

        return this.store
          .dispatch(new PatchScandataModel(patchModel))
          .pipe(map(() => updatedModel));
      }),
      this.handleError('scanDetailsSaveError'),
    );
  }

  updateScandataModels(updateScandataModels: (UpdateScandataModel & { pointcloudId: string })[]) {
    const updateList = updateScandataModels.map((model) => {
      const dto = {
        updateScandataModel: model,
        responseScandataModel: <ScandataModel | null>null,
        error: <string | null>null,
      };

      return this.getScanProjectUrl$(`/pointclouds/${dto.updateScandataModel.pointcloudId}`).pipe(
        switchMap((url) => {
          return this.http.patch<ScandataModel>(url, dto.updateScandataModel);
        }),
        map((response) => {
          dto.responseScandataModel = response;
          return dto;
        }),
        catchError((error) => {
          dto.error = error;
          return of(dto);
        }),
      );
    });

    return forkJoin(updateList).pipe(
      switchMap((result) => {
        const patchActions = result
          .filter((item) => isNil(item.error) && isDefined(item.responseScandataModel))
          .map((item) => item.responseScandataModel as ScandataModel)
          .map((updatedModel) => this.mapScandataModel(updatedModel))
          .map((updatedModel) => {
            const patchModel = {
              ...updatedModel,
              ...{
                captureDatetimeUtc: updatedModel.captureDatetimeUtc,
                scannerType: updatedModel.scannerType,
                notes: updatedModel.notes,
              },
            };

            return new PatchScandataModel(patchModel);
          });

        return this.store.dispatch(patchActions).pipe(map(() => result));
      }),
    );
  }

  deleteScandataModel(pointcloudId: string) {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
      switchMap(() => {
        return this.store.dispatch(new RemoveScandataModel(pointcloudId));
      }),
      map(() => pointcloudId),
    );
  }

  deleteScandataModels(scandataModels: ScandataModel[]) {
    const deleteList = scandataModels.map((model) => {
      const dto = { scandataModel: model, error: null };

      return this.getScanProjectUrl$(`/pointclouds/${dto.scandataModel.id}`).pipe(
        switchMap((url) => {
          return this.http.delete<void>(url);
        }),
        map(() => dto),
        catchError((error) => {
          dto.error = error;
          return of(dto);
        }),
      );
    });

    return forkJoin(deleteList).pipe(
      switchMap((result) => {
        const removeActions = result
          .filter((item) => isNil(item.error))
          .map((item) => new RemoveScandataModel(item.scandataModel.id));

        return this.store.dispatch(removeActions).pipe(map(() => result));
      }),
    );
  }

  private getScandata(): Observable<ScandataModel[]> {
    this.cancelGetScandata.next();

    return this.getQuerystring().pipe(
      switchMap((querystring) => this.getScanProjectUrl$(`/pointclouds?${querystring}`)),
      switchMap((url) => {
        const request = this.http.get<ScandataModel[]>(url);
        return this.loadingService.loadFrom(request, this);
      }),
      this.handleError('scanLoadError'),
      tap({ error: () => this.store.dispatch(ClearScandata) }),
      retry({ delay: (error: unknown) => this.prompUserToRetry(error) }),
      map((scans) => scans.map((scan) => this.mapScandataModel(scan))),
      map((scans) => scans.map((scan) => this.assignWeb3dId(scan))),
      takeUntil(this.cancelGetScandata),
    );
  }

  private prompUserToRetry(error: unknown) {
    return this.showRetryDialog().pipe(
      switchMap((retryClicked) => {
        return retryClicked
          ? this.store.dispatch(new ClearError('scanLoadError'))
          : throwError(() => error);
      }),
    );
  }

  private getAndCacheScandataModel(pointcloudId: string) {
    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}`).pipe(
      switchMap((url) => this.http.get<ScandataModel>(url)),
      map((model) => this.mapScandataModel(model)),
      map((model) => ({ ...model, fullyLoaded: true }) as ScandataModel),
      switchMap((scan) => this.cacheScandataModel(scan)),
    );
  }

  private cacheScandataModel(scan: ScandataModel) {
    return this.store.selectOnce(ScandataState.getScan(scan.id)).pipe(
      switchMap((storeScan) =>
        isNil(storeScan)
          ? this.store.dispatch(new AddScan(this.assignWeb3dId(scan)))
          : this.store.dispatch(new PatchScandataModel(scan)),
      ),
      map(() => scan),
    );
  }

  private mapScandataModel(scandataModel: ScandataModel): ScandataModel {
    this.assignScandataModelStatus(scandataModel);
    this.convertScandataModelDates(scandataModel);
    return scandataModel;
  }

  private assignScandataModelStatus(scandataModel: ScandataModel) {
    scandataModel.pointcloudStatus = this.statusMap.get(scandataModel.status) as PointcloudStatus;
  }

  private convertScandataModelDates(scandataModel: ScandataModel) {
    if (isDefined(scandataModel.captureDatetimeUtc))
      scandataModel.captureDatetimeUtc = new Date(scandataModel.captureDatetimeUtc);

    if (isDefined(scandataModel.uploadedDate))
      scandataModel.uploadedDate = new Date(scandataModel.uploadedDate);
  }

  private assignWeb3dId(scan: ScandataModel) {
    scan.web3dId = uuidv4();
    return scan;
  }

  private getQuerystring() {
    return this.store.selectOnce(ScandataState.query).pipe(
      map((query) => {
        const parts: string[] = [];

        parts.push(this.getSortingQueryString(query.sorting));
        parts.push(this.getPagingQueryString(query.paging));
        parts.push(this.getFiltersQuerystring(query.filters));

        return parts.filter((part) => part.length > 0).join('&');
      }),
    );
  }

  private getSortingQueryString(sorting: Sorting) {
    const sortingQuery =
      sorting.sortBy?.trim().length > 0 ? `sortBy=${sorting.sortBy} ${sorting.sortDirection}` : '';
    return sortingQuery;
  }

  private getPagingQueryString(paging: Paging) {
    const pagingQuery = `pageSize=${paging.pageSize}&pageIndex=${paging.pageIndex}`;
    return pagingQuery;
  }

  private getFiltersQuerystring(filters: Filters) {
    const filterPairs: string[] = [];

    if (isDefined(filters.name) && filters.name.length > 0)
      filterPairs.push(`name=*${this.escapeFilterSpecialChars(filters.name)}/i`);

    if (isDefined(filters.captureFromDate)) {
      const dateValue = new Date(filters.captureFromDate).toISOString();
      filterPairs.push(`captureDate>=${this.escapeFilterSpecialChars(dateValue)}`);
    }

    if (isDefined(filters.captureToDate)) {
      const dateValue = new Date(filters.captureToDate).toISOString();
      filterPairs.push(`captureDate<=${this.escapeFilterSpecialChars(dateValue)}`);
    }

    if (isDefined(filters.status) && filters.status.length > 0) {
      const statusFilter = [...this.statusMap]
        .filter(([, status]) => filters.status?.some((item) => item === status))
        .map(([apiStatus]) => `status=${this.escapeFilterSpecialChars(apiStatus)}`)
        .join('|');
      filterPairs.push(`(${statusFilter})`);
    }

    if (isDefined(filters.scannerType) && filters.scannerType.length > 0)
      filterPairs.push(`scannerType=*${this.escapeFilterSpecialChars(filters.scannerType)}/i`);

    if (isDefined(filters.tags) && filters.tags.length > 0) {
      const tagsFilter = filters.tags.map((tag) => `tags=${this.escapeFilterSpecialChars(tag)}`);

      if (filters.tagsMatchAll) {
        filterPairs.push(...tagsFilter);
      } else {
        filterPairs.push(`(${tagsFilter.join('|')})`);
      }
    }

    if (isDefined(filters.uploadedBy) && filters.uploadedBy.length > 0) {
      const uploadedByFilter = filters.uploadedBy
        .map((item) => `uploadedByTIDUuid=${this.escapeFilterSpecialChars(item)}`)
        .join('|');
      filterPairs.push(`(${uploadedByFilter})`);
    }

    if (isDefined(filters.isClassified)) filterPairs.push(`isClassified=${filters.isClassified}`);

    if (isDefined(filters.containsStations)) filterPairs.push(`numberOfStations>0`);

    return filterPairs.length > 0 ? `filterBy=${encodeURIComponent(filterPairs.join(','))}` : '';
  }

  /**
   * Escape special characters used in filter values.
   * More information: https://alirezanet.github.io/Gridify/guide/filtering#escaping
   */
  private escapeFilterSpecialChars(value: string) {
    return value.replace(/([(),|\\]|\/i)/g, '\\$1');
  }

  private showRetryDialog() {
    const dialogData = new DialogData(
      'Loading Error',
      'Something went wrong loading your scan data.',
      {
        text: 'Retry',
        color: 'primary',
      },
      null,
      true,
    );

    return this.dialogService.show(dialogData).pipe(take(1));
  }
}
