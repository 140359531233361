<md-icon class="!flex !text-lg/5 w-7 h-full items-center" [modusMenuTriggerFor]="menu"
  >more_vertical</md-icon
>

<md-menu #menu>
  <md-menu-item (itemClick)="onModelDetailClick()">Show Details</md-menu-item>
  <md-menu-item (itemClick)="onModelFitToViewClick()" [disabled]="fitToViewDisabled">
    Fit to View
  </md-menu-item>
</md-menu>
