<div class="flex flex-auto flex-col items-center justify-center p-12">
  <img src="../../../assets/list/no-scans.png" class="w-[260px]" />
  <div class="text-lg font-semibold mt-6">No Scan Data</div>
  <div class="text-center text-sm mt-2">
    @switch (reason) {
      @case (emptyReason.NoUploads) {
        Looks like you haven't added any scan data yet.
      }
      @case (emptyReason.NoFilterResults) {
        Current filter selection returned no matching scan data.
      }
      @case (emptyReason.NoGeolocated) {
        No geolocated scan data.
      }
      @case (emptyReason.NoSelected) {
        No selected scan data.
      }
    }
  </div>
</div>
