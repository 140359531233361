import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { checkValidSasToken } from '../../utils/sas-token-validator';
import { DownloadParameters } from './download-parameters.model';

export interface DownloadParametersStateModel {
  [key: string]: DownloadParameters;
}

export class CacheDownloadParameters {
  static readonly type = '[DownloadParametersState] CacheDownloadParameters';
  constructor(
    public pointcloudId: string,
    public downloadParameters: DownloadParameters,
  ) {}
}

@State<DownloadParametersStateModel>({
  name: 'downloadParametersStateModel',
  defaults: {},
})
@Injectable()
export class DownloadParametersState {
  static getDownloadParameters(
    pointcloudId: string,
  ): (state: DownloadParametersStateModel) => DownloadParameters | null {
    return createSelector([DownloadParametersState], (state: DownloadParametersStateModel) => {
      const downloadParameters = state[pointcloudId];
      return downloadParameters && checkValidSasToken(downloadParameters.sasToken)
        ? downloadParameters
        : null;
    });
  }

  @Action(CacheDownloadParameters)
  cacheDownloadParameters(
    ctx: StateContext<DownloadParametersStateModel>,
    { pointcloudId, downloadParameters }: CacheDownloadParameters,
  ) {
    ctx.patchState({ [pointcloudId]: downloadParameters });
  }
}
