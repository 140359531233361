import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { AppContext } from '../app-state/app.models';
import { AppState, AppStateModel } from '../app-state/app.state';
import {
  ClearAuth,
  SetAuthToken,
  SetConnectToken,
  SetPkceVerifier,
  SetReturnPath,
  SetUser,
} from './auth.actions';
import { AuthToken, Role, User } from './auth.models';

export interface AuthStateModel {
  returnPath?: string;
  authToken?: AuthToken;
  user?: User;
  verifier?: string;
  connectToken?: string;
}

@State<AuthStateModel>({
  name: 'authState',
})
@Injectable()
export class AuthState {
  @Selector() static returnPath(state: AuthStateModel) {
    return state.returnPath;
  }

  @Selector() static authToken(state: AuthStateModel) {
    return state.authToken;
  }

  @Selector([AuthState, AppState]) static accessToken(
    state: AuthStateModel,
    appState: AppStateModel,
  ) {
    if (appState.context === AppContext.ConnectExtension) {
      return state.connectToken;
    }

    return state.authToken?.access_token;
  }

  @Selector() static idToken(state: AuthStateModel) {
    return state.authToken?.id_token;
  }

  @Selector() static refreshToken(state: AuthStateModel) {
    return state.authToken?.refresh_token;
  }

  @Selector() static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector() static userRole(state: AuthStateModel): Role | undefined {
    return state.user?.role ?? Role.None;
  }

  @Selector() static pkceVerifier(state: AuthStateModel) {
    return state.verifier;
  }

  @Selector([AuthState, AppState]) static loggedIn(state: AuthStateModel, appState: AppStateModel) {
    return appState.context === AppContext.ConnectExtension
      ? isDefined(state.connectToken)
      : isDefined(state.authToken) && !isExpired(state.authToken);
  }

  @Action(SetReturnPath) setReturnPath(ctx: StateContext<AuthStateModel>, action: SetReturnPath) {
    ctx.patchState({ returnPath: action.returnPath });
  }

  @Action(SetAuthToken) setAuthToken(ctx: StateContext<AuthStateModel>, action: SetAuthToken) {
    ctx.patchState({ authToken: action.authToken });
  }

  @Action(SetConnectToken) setConnectToken(
    ctx: StateContext<AuthStateModel>,
    action: SetConnectToken,
  ) {
    ctx.patchState({ connectToken: action.connectToken });
  }

  @Action(SetUser) setUser(ctx: StateContext<AuthStateModel>, action: SetUser) {
    ctx.patchState({ user: action.user });
  }

  @Action(SetPkceVerifier) setPkceVerifier(
    ctx: StateContext<AuthStateModel>,
    action: SetPkceVerifier,
  ) {
    ctx.patchState({ verifier: action.verifier });
  }

  @Action(ClearAuth) clearAuth(ctx: StateContext<AuthStateModel>) {
    ctx.setState({});
  }
}

function isExpired(token: AuthToken) {
  const expired = Date.now() > token.expires_at;
  return expired;
}
