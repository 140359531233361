import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { Observable, map } from 'rxjs';
import { FileSizePipe } from '../../../../../../libs/ngx-common/src';
import { LoadingService } from '../../loading/loading.service';
import { PercentagePipe } from '../../pipes/percentage.pipe';
import { ProjectQuota, ProjectQuotaService } from '../../quota/project-quota.service';
import { UsageBarComponent } from './usage-bar/usage-bar.component';

interface ProjectQuotaView extends ProjectQuota {
  quotaUsedByAccountPercent: number;
  quotaUsedByProjectPercent: number;
}

@UntilDestroy()
@Component({
  selector: 'sd-config-storage',
  standalone: true,
  imports: [
    CommonModule,
    UsageBarComponent,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    MatProgressBarModule,
    MatSliderModule,
    PercentagePipe,
    FileSizePipe,
  ],
  templateUrl: './storage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageComponent {
  isLoading$!: Observable<boolean>;
  projectQuota$!: Observable<ProjectQuotaView>;

  constructor(
    private loadingService: LoadingService,
    private projectQuotaService: ProjectQuotaService,
  ) {
    this.isLoading$ = this.loadingService.isLoading$(this);
    this.projectQuota$ = this.getPojectQuota().pipe(
      map((projectQuota) => {
        const quotaUsedByAccountPercent =
          Math.abs(projectQuota.quota) > 0
            ? (projectQuota.quotaUsedByAccount / projectQuota.quota) * 100
            : 100;

        const quotaUsedByProjectPercent =
          Math.abs(projectQuota.quota) > 0
            ? (projectQuota.quotaUsedByProject / projectQuota.quota) * 100
            : 100;

        return {
          ...projectQuota,
          quotaUsedByAccountPercent,
          quotaUsedByProjectPercent,
        } satisfies ProjectQuotaView;
      }),
    );
  }

  private getPojectQuota() {
    this.loadingService.startLoading(this);
    return this.loadingService.loadFrom<ProjectQuota>(
      this.projectQuotaService.getProjectQuota(),
      this,
    );
  }
}
