import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from 'rxjs';
import { CameraProjection } from '../../connect-3d-ext/camera/camera.models';
import { CameraState } from '../../connect-3d-ext/camera/camera.state';
import { FitToView } from '../../connect-3d-ext/host-3d.actions';
import { Host3dService } from '../../connect-3d-ext/host-3d.service';
import { PatchScandataModel } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { CurrentStation, Station } from '../../station/station.models';
import { StationState } from '../../station/station.state';
import { ScandataTreeComponent } from '../scandata-tree/scandata-tree.component';

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree-view',
  standalone: true,
  imports: [CommonModule, ScandataTreeComponent],
  templateUrl: './scandata-tree-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeViewComponent {
  @Select(StationState.currentStation) currentStation$!: Observable<CurrentStation | undefined>;
  @Select(CameraState.projection) cameraProjection$!: Observable<CameraProjection>;

  @Input() scandata!: ScandataModel[];
  @Output() modelDetailClick = new EventEmitter<ScandataModel>();

  constructor(
    private host3dService: Host3dService,
    private store: Store,
  ) {}

  modelIconClick(scan: ScandataModel) {
    const show = !scan.showInScene;
    if (show) {
      this.host3dService.showScan(scan).pipe(take(1)).subscribe();
    } else {
      this.host3dService.hideScan(scan).pipe(take(1)).subscribe();
    }
  }

  modelNameClick(model: ScandataModel) {
    if (model.numberOfStations > 0) {
      this.toggleExpand(model);
    }
  }

  modelDetailClicked(model: ScandataModel) {
    this.modelDetailClick.emit(model);
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.store.dispatch(new FitToView([model]));
  }

  toggleExpand(scan: ScandataModel) {
    const expand = !scan.expanded;

    this.store.dispatch(
      new PatchScandataModel({
        id: scan.id,
        expanded: expand,
      }),
    );

    if (expand) {
      this.host3dService.loadScanStations(scan).pipe(take(1)).subscribe();
    }
  }

  stationIconClick(station: Station) {
    this.host3dService.setCurrentStation(station).pipe(take(1)).subscribe();
  }

  stationNameClick(station: Station) {
    this.host3dService.setCurrentStation(station).pipe(take(1)).subscribe();
  }
}
