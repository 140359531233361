<div class="h-full flex flex-1 bg-gray-light p-4 pt-2">
  <mat-tab-group
    class="flex-1"
    disableRipple
    mat-stretch-tabs="false"
    [selectedIndex]="selectedTabIndex$ | async"
    (selectedTabChange)="tabChange($event)"
  >
    <mat-tab label="Cloud Storage">
      <ng-template matTabContent>
        <sd-config-storage
          class="flex flex-1 flex-col border-t border-gray-0 pt-4"
        ></sd-config-storage>
      </ng-template>
    </mat-tab>
    <mat-tab label="Classification Template">
      <ng-template matTabContent>
        <sd-config-classification
          class="h-full flex flex-1 flex-col border-t border-gray-0 pt-4"
        ></sd-config-classification>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
