<div
  #map
  leaflet
  class="flex flex-col h-full outline-0"
  (leafletMapReady)="setMapInstance($event)"
  (leafletMapZoomEnd)="zoomed($event)"
  (leafletMapMoveEnd)="moved()"
  [leafletOptions]="options"
>
  @if (tileLayer !== undefined) {
    <div [leafletLayer]="tileLayer"></div>
  }
  <div [leafletLayer]="scanLayer"></div>
  <div [leafletLayer]="toolLayer"></div>
</div>
