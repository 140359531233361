import { AuthToken, User } from './auth.models';

export class SetReturnPath {
  static readonly type = '[AuthState] SetReturnPath';
  constructor(public readonly returnPath: string) {}
}

export class SetAuthToken {
  static readonly type = '[AuthState] SetAuthToken';
  constructor(public readonly authToken: AuthToken) {}
}

export class SetConnectToken {
  static readonly type = '[AuthState] SetConnectToken';
  constructor(public readonly connectToken: string) {}
}

export class SetUser {
  static readonly type = '[AuthState] SetUser';
  constructor(public readonly user: User) {}
}

export class SetPkceVerifier {
  static readonly type = '[AuthState] SetPkceVerifier';
  constructor(public readonly verifier: string) {}
}

export class ClearAuth {
  static readonly type = '[AuthState] ClearAuth';
}
