/**
 * Token obtained from TID in standalone mode.
 */
export interface AuthToken {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
}

/**
 * Returned from the TID '/user_info' endpoint.
 */
export interface UserInfo {
  iss: string;
  sub: string;
  identity_type: string;
  given_name: string;
  family_name: string;
  email_verified: boolean;
  locale: string;
  preferred_mfa_setting: string;
  picture: string;
  data_region: string;
  updated_at: string;
  zoneinfo: string;
  country: string;
}

/**
 * Response object returned from the Seurat API '/users'
 * and Seurat API '/users/me' endpoint.
 */
export interface UserResponse {
  /** Connect user id. Not the same as TID. */
  id: string;

  /** Trimble Identity - TID. */
  tidUuid: string;

  title: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  language: string;
  role: Role;
}

/**
 * Connect user role;
 */
export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
  None = 'NONE',
}

/**
 * Application-defined User object.
 *
 * Properties are mapped from `UserDetailsResponse`,
 * and `role` is mapped from `UserResponse`.
 */
export interface User {
  /** Trimble Identity - TID. */
  id: string;

  /** Connect user id. Not the same as TID. */
  connectId: string;

  firstName: string;
  lastName: string;
  email: string;
  language: string;
  role: Role;
}

export function createUser(user: UserResponse): User {
  return {
    id: user.tidUuid,
    connectId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    language: user.language,
    role: user.role,
  };
}
