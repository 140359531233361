import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';
import { SetUser } from '../auth/auth.actions';
import { UserResponse, createUser } from '../auth/auth.models';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getUsers(): Observable<UserResponse[]> {
    return this.getScanProjectUrl$('/users').pipe(
      switchMap((url) => {
        return this.http.get<UserResponse[]>(url);
      }),
    );
  }

  loadUser() {
    return this.getCurrentUser().pipe(
      map((me) => createUser(me)),
      switchMap((user) => this.store.dispatch(new SetUser(user))),
    );
  }

  private getCurrentUser() {
    return this.getScanProjectUrl$('/users/me').pipe(
      switchMap((url) => this.http.get<UserResponse>(url)),
    );
  }
}
