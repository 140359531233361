import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { Observable, Subscriber, filter, map, of, switchMap } from 'rxjs';
import { HANDLE_ERROR } from '../error-handling/error-handler-operator';
import { ScandataModel } from '../scandata/scandata.models';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import {
  ClearClassificationSchemes,
  SetClassificationSchemes,
} from './classification-scheme.actions';
import {
  ClassificationScheme,
  createNewClassificationScheme,
  isClassificationSchemeArray,
} from './classification-scheme.model';
import { ClassificationSchemeState } from './classification-scheme.state';
import { Classification } from './classification.model';

/** Default classifications defined by backend */
const PREDEFINED_CLASSIFICATION_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 12];

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private handleError = inject(HANDLE_ERROR);

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  getClassifications(scandataModel: ScandataModel): Observable<Classification[]> {
    if (isNil(scandataModel.classStatistics)) return of([]);

    return this.getClassificationSchemes().pipe(
      map((classificationSchemes) => {
        const total = Object.values(scandataModel.classStatistics).reduce(
          (sum, current) => sum + current,
          0,
        );

        const modelClassifications: Classification[] = Object.entries(
          scandataModel.classStatistics,
        ).map(([key, value]) => {
          const classificationSchemeId = parseInt(key);
          const classificationScheme =
            classificationSchemes.find((item) => item.id === classificationSchemeId) ??
            createNewClassificationScheme(classificationSchemeId);

          return <Classification>{
            classificationScheme,
            value,
            percentage: total > 0 ? (value / total) * 100 : 0,
          };
        });

        return modelClassifications;
      }),
    );
  }

  getClassificationSchemes(): Observable<ClassificationScheme[]> {
    return this.store
      .selectOnce(ClassificationSchemeState.classifications)
      .pipe(
        switchMap((classificationSchemes) =>
          isDefined(classificationSchemes)
            ? of(classificationSchemes)
            : this.getAndCacheClassificationSchemes(),
        ),
      );
  }

  resetClassificationSchemes() {
    return this.getScanProjectUrl$('/classifications').pipe(
      switchMap((url) => {
        return this.http.delete<void>(url);
      }),
      this.handleError('classificationResetError'),
      switchMap(() => this.store.dispatch(new ClearClassificationSchemes())),
      switchMap(() => this.getAndCacheClassificationSchemes()),
    );
  }

  saveClassificationSchemes(classificationSchemes: ClassificationScheme[]) {
    return this.getScanProjectUrl$('/classifications').pipe(
      switchMap((url) => {
        return this.http.put<ClassificationScheme[]>(url, classificationSchemes);
      }),
      this.handleError('classificationSaveError'),
      map((schemes) => this.markPredefinedClassificationSchemes(schemes)),
      switchMap((schemes) => this.cacheClassificationSchemes(schemes)),
    );
  }

  readAndParseFile(file: File): Observable<ClassificationScheme[]> {
    return readFile(file).pipe(
      filter(isDefined),
      map((fileContent) => {
        const classifications = JSON.parse(fileContent) as ClassificationScheme[];
        const valid = isClassificationSchemeArray(classifications) && classifications.length > 0;

        if (!valid) throw Error('File not valid');

        return classifications;
      }),
      this.handleError('classificationImportError'),
    );
  }

  private getAndCacheClassificationSchemes() {
    return this.getScanProjectUrl$('/classifications').pipe(
      switchMap((url) => {
        return this.http.get<ClassificationScheme[]>(url);
      }),
      this.handleError('classificationLoadError'),
      map((classificationSchemes) =>
        this.markPredefinedClassificationSchemes(classificationSchemes),
      ),
      switchMap((classificationSchemes) => this.cacheClassificationSchemes(classificationSchemes)),
    );
  }

  private markPredefinedClassificationSchemes(classificationSchemes: ClassificationScheme[]) {
    /** TODO: should have the api return a property indicating if the scheme is in the default/predefined list */
    classificationSchemes.forEach((classificationScheme) => {
      classificationScheme.predefined = PREDEFINED_CLASSIFICATION_IDS.includes(
        classificationScheme.id,
      );
    });

    return classificationSchemes;
  }

  private cacheClassificationSchemes(classificationSchemes: ClassificationScheme[]) {
    return this.store
      .dispatch(new SetClassificationSchemes(classificationSchemes))
      .pipe(map(() => classificationSchemes));
  }
}

function readFile(file: File): Observable<string | null> {
  return new Observable((subscriber: Subscriber<string | null>): void => {
    const fileReader = new FileReader();
    fileReader.onload = (ev: ProgressEvent): void => {
      subscriber.next((ev.target as FileReader).result?.toString());
    };
    fileReader.onerror = (ev: ProgressEvent): void => {
      subscriber.error(ev);
    };
    fileReader.readAsText(file);
  });
}
