<mat-toolbar>
  <nav
    class="bg-gray-0 rounded-[4px]"
    mat-tab-nav-bar
    [tabPanel]="tabPanel"
    color="primary"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
  >
    @for (link of links; track link.title) {
      <a
        id="menu-bar-tab-{{ link.title.toLowerCase() }}"
        mat-tab-link
        [active]="(activeLink$ | async) === link.route"
        (click)="navigate(link.route)"
        disableRipple
      >
        <md-icon>{{ link.icon }}</md-icon>
      </a>
    }
  </nav>

  <div class="ml-4 w-1/2 max-w-72">
    <!-- search -->
    <modus-form-field>
      <md-icon modus-icon-prefix>search</md-icon>
      @if (showTextFilterClear$ | async) {
        <button modus-icon-suffix (click)="clearTextFilter()">
          <md-icon>close</md-icon>
        </button>
      }
      <input modus-input placeholder="Find" [formControl]="textFilterControl" />
    </modus-form-field>
  </div>

  <div class="flex grow h-12 w-1/2 justify-end">
    <div class="flex items-center">
      <button
        id="menu-bar-filters"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Filter"
        (click)="toggleOptionsPanelView(panelView.ListFilters)"
        [ngClass]="{ 'text-trimble-blue bg-gray-1': (filterPanelActive$ | async) }"
      >
        <md-icon>filter</md-icon>

        @if ((filterCount$ | async) ?? 0 > 0) {
          <modus-badge class="absolute -top-[18px] -right-[12px]" type="counter">{{
            filterCount$ | async
          }}</modus-badge>
        }
      </button>

      <button
        id="menu-bar-view-in-3d"
        modus-icon-button
        class="mx-1 text-base font-bold"
        modus-tooltip="3D View"
        (click)="view3D()"
      >
        3D
      </button>

      <button
        id="menu-bar-downloads"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Download Status"
        (click)="toggleOptionsPanelView(panelView.DownloadStatus)"
        [ngClass]="{ 'text-trimble-blue bg-gray-1': (downloadStatusPanelActive$ | async) }"
      >
        <md-icon>download_line</md-icon>

        @if ((downloadCount$ | async) ?? 0 > 0) {
          <modus-badge class="absolute -top-[18px] -right-[12px]" type="counter">{{
            downloadCount$ | async
          }}</modus-badge>
        }
      </button>

      <button
        id="menu-bar-refresh"
        modus-icon-button
        class="mx-1"
        modus-tooltip="Reload Scan Data"
        (click)="refreshScanData()"
      >
        <md-icon>refresh</md-icon>
      </button>

      <button id="menu-bar-import" color="secondary" modus-flat-button class="ml-3">Import</button>
    </div>
  </div>
</mat-toolbar>

<mat-tab-nav-panel #tabPanel class="flex-1 overflow-hidden border-t border-t-gray-1">
  <mat-drawer-container class="h-full">
    <mat-drawer [opened]="showOptionsPanel$ | async" mode="side" position="end" disableClose="true">
      <sd-options-panel></sd-options-panel>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-tab-nav-panel>
