import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(sizeInGb: number, decimalPlaces = 2): string {
    if (Number.isFinite(sizeInGb) && sizeInGb > 0) {
      const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const bytes = sizeInGb * 1024 ** 3;

      for (let i = 0; i < suffixes.length; i++) {
        if (bytes < 1024 ** (i + 1)) {
          return (bytes / 1024 ** i).toFixed(i === 0 ? 0 : decimalPlaces) + ' ' + suffixes[i];
        }
      }
    }
    return '';
  }
}
