<div class="relative">
  @if (showProgressBar$ | async) {
    <div class="w-full absolute">
      <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  }
</div>

<div class="flex flex-row pl-2 py-3 border-b border-gray-1">
  <div (click)="onExitDetailClick()" class="text-primary cursor-pointer">Project Scan Data</div>
  <div class="ml-1">> Details</div>
</div>

<div
  modus-tooltip="{{ (currentModel$ | async)!.name }}"
  class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-primary pl-2 pt-2 pb-2 pr-1 font-semibold"
>
  {{ (currentModel$ | async)!.name }}
</div>

<mat-tab-group preserveContent="true" class="mds-small h-full overflow-hidden" disableRipple>
  <mat-tab label="Properties" labelClass="!p-0">
    <div class="h-full overflow-y-scroll border-t border-gray-1 p-4">
      <sd-properties
        [scandataModel]="(currentModel$ | async)!"
        [readonly]="true"
        [showTags]="true"
      ></sd-properties>
    </div>
  </mat-tab>
  <mat-tab label="Classification" labelClass="!p-0">
    <div class="h-full overflow-y-auto border-t border-gray-1 p-4">
      <sd-scan-3d-classification
        class="h-full"
        [scandataModel]="(currentModel$ | async)!"
        [scan3dStyle]="(scan3dStyle$ | async)!"
        (styleChange)="onStyleChange($event)"
      ></sd-scan-3d-classification>
    </div>
  </mat-tab>
  <mat-tab label="Styling" labelClass="!p-0">
    <div class="h-full border-t border-gray-1 p-4">
      <sd-scan-3d-styling
        class="h-full"
        [scan3dStyle]="(scan3dStyle$ | async)!"
        (styleChange)="onStyleChange($event)"
      ></sd-scan-3d-styling>
    </div>
  </mat-tab>
</mat-tab-group>
