export enum ConnectPanelStatus {
  CheckingStatus = 'CheckingStatus',
  IngestionError = 'IngestionError',
  QuotaExceeded = 'QuotaExceeded',
  ReadyToTile = 'ReadyToTile',
  ReadyToView = 'ReadyToView',
  ReadyToRefresh = 'ReadyToRefresh',
  Refreshing = 'Refreshing',
  TilingError = 'TilingError',
  Tiling = 'Tiling',
  Viewing = 'Viewing',
}
