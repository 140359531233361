import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  Observable,
  debounce,
  defaultIfEmpty,
  filter,
  map,
  startWith,
  switchMap,
  take,
  timer,
} from 'rxjs';
import { AppRoute } from '../../app.routes';
import { ConnectService } from '../../connect/connect.service';
import { ClearError } from '../../error-handling/error.actions';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import { DownloadState } from '../../options-panel/download/download.state';
import { OptionsPanelComponent } from '../../options-panel/options-panel.component';
import { OptionsPanelService } from '../../options-panel/options-panel.service';
import { OptionsPanelView } from '../../options-panel/options-panel.state';
import { SetLastVisitedRoute } from '../../route/route.state';
import { SetTextFilter } from '../../scandata/scandata.actions';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { tabLinks } from './tab-links';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ModusFormFieldModule,
    ModusInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTabsModule,
    OptionsPanelComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tab-host.component.html',
  styleUrls: ['./tab-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabHostComponent implements OnInit {
  @Select(ScandataState.filterCount) filterCount$!: Observable<number>;
  @Select(ScandataState.textFilter) textFilter$!: Observable<string | undefined>;
  @Select(DownloadState.activeDownloadCount) downloadCount$!: Observable<number>;

  links = tabLinks;
  activeLink$: Observable<string>;

  panelView = OptionsPanelView;
  showOptionsPanel$!: Observable<boolean>;
  filterPanelActive$!: Observable<boolean>;
  downloadStatusPanelActive$!: Observable<boolean>;
  showTextFilterClear$!: Observable<boolean>;

  textFilterControl = new FormControl<string | null>(null);

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private optionsPanelService: OptionsPanelService,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
    private connectService: ConnectService,
    private store: Store,
  ) {
    let snapshot = route.snapshot;

    while (snapshot.firstChild) {
      snapshot = snapshot.firstChild;
    }

    this.activeLink$ = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const url = (evt as NavigationEnd).urlAfterRedirects;
        return url.startsWith('/') ? url.slice(1) : url;
      }),
      startWith(snapshot.url[0].path),
      defaultIfEmpty(snapshot.params['path']),
    );

    this.activeLink$.pipe(untilDestroyed(this)).subscribe((path) => {
      const rememberRoutes = [AppRoute.MapView, AppRoute.ListView];
      if (!rememberRoutes.map((route) => route.toString()).includes(path)) return;

      this.store.dispatch(new SetLastVisitedRoute(path as AppRoute));
    });
  }

  async ngOnInit() {
    this.showOptionsPanel$ = this.optionsPanelService.view$.pipe(
      map((view) => view !== OptionsPanelView.None),
    );

    this.filterPanelActive$ = this.optionsPanelService.view$.pipe(
      map((view) => view === OptionsPanelView.ListFilters),
    );

    this.downloadStatusPanelActive$ = this.optionsPanelService.view$.pipe(
      map((view) => view === OptionsPanelView.DownloadStatus),
    );

    await this.loadDataOnScandataBrowserCommand();

    this.showTextFilterClear$ = this.textFilter$.pipe(map(isDefined));

    this.setInitialTextFilterValue();
    this.subscribeToTextFilterChange();
  }

  navigate(route: AppRoute) {
    this.router.navigate([route]);
  }

  toggleOptionsPanelView(view: OptionsPanelView) {
    this.optionsPanelService.setView(view, true);
  }

  refreshScanData() {
    this.store
      .dispatch(new ClearError('scanLoadError'))
      .pipe(
        switchMap(() => this.scandataService.refreshScandata()),
        switchMap(() => this.featureLayerService.loadFeatures()),
      )
      .subscribe(noopErrorObserver);
  }

  view3D() {
    this.connectService.goTo3dExtension();
  }

  private async loadDataOnScandataBrowserCommand() {
    const workspace = await this.connectService.getWorkspace();
    workspace.command$
      .pipe(
        filter((event) => event.data === 'scandata_browser_command'),
        switchMap(() => this.scandataService.loadScandata()),
        untilDestroyed(this),
      )
      .subscribe(noopErrorObserver);
  }

  clearTextFilter() {
    this.textFilterControl.reset();
  }

  private setInitialTextFilterValue() {
    this.textFilter$.pipe(take(1)).subscribe((value) => {
      this.textFilterControl.reset(value);
    });
  }

  private subscribeToTextFilterChange() {
    this.textFilterControl.valueChanges
      .pipe(
        debounce(() => timer(250)),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.store.dispatch(new SetTextFilter(value ?? undefined));
      });
  }
}
