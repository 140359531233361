import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { isDefined, isNil, isNumeric } from '@trimble-gcs/common';
import { ModusCheckboxModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { FileSizePipe, PointCountPipe } from '@trimble-gcs/ngx-common';
import { ChipContainerComponent } from '../../chip-container/chip-container.component';
import { Sorting } from '../../scandata/scandata-query.models';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';

@Component({
  selector: 'sd-scandata-table',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    MatTableModule,
    MatSortModule,
    FileSizePipe,
    PointCountPipe,
    ChipContainerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTableComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: true }) private readonly sort!: MatSort;

  readonly dataSource = new MatTableDataSource<ScandataModel>();

  readonly displayedColumns = [
    'selected',
    'thumbnailUrl',
    'name',
    'status',
    'uploadedBy',
    'uploadedDate',
    'pointCount',
    'fileSizeGB',
    'tags',
  ];

  @Input() set data(value: ScandataModel[] | null) {
    this.dataSource.data = value ?? [];
  }

  @Input() sorting!: Sorting;
  @Input() selectedIds!: string[] | null;

  @Output() selectionChange = new EventEmitter<ScandataModel[]>();
  @Output() chipClick = new EventEmitter<ScandataModel>();
  @Output() sortingChange = new EventEmitter<Sorting>();

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor();
    this.dataSource.sort = this.sort;

    this.setInitialSort();
  }

  isAllSelected() {
    return this.dataSource.data.length > 0 && this.dataSource.data.every((model) => model.selected);
  }

  isSomeSelected() {
    return (
      this.dataSource.data.some((model) => model.selected) &&
      this.dataSource.data.some((model) => !model.selected)
    );
  }

  toggleAllRows() {
    const selected = !this.isAllSelected();

    //mat table does not have a property to get the data as it is currently sorted in the UI
    const data = selected
      ? this.dataSource.sortData(this.dataSource.data, this.sort)
      : this.dataSource.data;

    data.forEach((model) => (model.selected = selected));

    this.selectionChange.emit(data);
  }

  checkboxKeyDown(model: ScandataModel) {
    this.toggleModelSelect(model);
  }

  rowClicked($event: MouseEvent, model: ScandataModel, index: number) {
    if ($event.shiftKey) return this.toggleRangeSelected(model, index);

    const checkboxClicked = isEventFromModusCheckbox($event);
    if ($event.ctrlKey || checkboxClicked) return this.toggleModelSelect(model);

    this.toggleSingleSelected(model);
  }

  getIcon(model: ScandataModel) {
    switch (model.pointcloudStatus) {
      case PointcloudStatus.Failed:
        return { icon: 'alert', color: 'red', message: 'Failed' };
      case PointcloudStatus.Ready:
        return { icon: 'check_circle', color: 'green', message: 'Ready' };
      case PointcloudStatus.Processing:
        return { icon: 'more_circle', color: 'orange', message: 'Processing' };
      default:
        return { icon: '', color: 'black', message: '' };
    }
  }

  getScanId(index: number, scan: ScandataModel) {
    return scan.id;
  }

  isScanReady(model: ScandataModel) {
    return model.pointcloudStatus === PointcloudStatus.Ready;
  }

  scanHasPreview(model: ScandataModel) {
    return model.thumbnailUrl?.length ?? 0 > 0;
  }

  scanHasStation(model: ScandataModel) {
    return model.numberOfStations > 0;
  }

  onChipClick(event: Event, selectedModel: ScandataModel) {
    event.stopPropagation();

    const currentSelection = this.dataSource.data.filter((model) => model.selected);
    currentSelection.forEach((model) => (model.selected = false));

    if (!selectedModel.selected) selectedModel.selected = true;

    this.chipClick.emit(selectedModel);
  }
  onSortChange(sort: Sort) {
    const sorting = this.sorting;
    const sortChanged =
      sorting?.sortBy !== sort.active || sorting?.sortDirection !== sort.direction;
    if (!sortChanged) return;

    const sortBy = sort.direction === '' ? '' : sort.active;
    const newSorting: Sorting = {
      sortBy,
      sortDirection: sort.direction,
    };

    this.sortingChange.emit(newSorting);
  }

  private setInitialSort() {
    this.sort.sort({
      id: this.sorting.sortBy,
      start: this.sorting.sortDirection,
      disableClear: false,
    });
  }

  private toggleModelSelect(selectedModel: ScandataModel) {
    selectedModel.selected = !selectedModel.selected;
    this.selectionChange.emit([selectedModel]);
  }

  private toggleRangeSelected(selectedModel: ScandataModel, selectedIndex: number) {
    if (isNil(this.selectedIds) || this.selectedIds.length === 0) return;

    //mat table does not have a property to get the data as it is currently sorted in the UI
    const data = this.dataSource.sortData(this.dataSource.data, this.sort);

    const prevSelectedId = this.selectedIds.slice(-1)[0];
    const prevSelectedIndex = data.findIndex((scan, index) => {
      return scan.id === prevSelectedId ? index : null;
    });

    const startIndex = selectedIndex < prevSelectedIndex ? selectedIndex : prevSelectedIndex;
    const endIndex = selectedIndex > prevSelectedIndex ? selectedIndex : prevSelectedIndex;

    const selection = data.filter((_, index) => index >= startIndex && index <= endIndex);
    if (selectedIndex < prevSelectedIndex) selection.reverse();

    const select = !selectedModel.selected;

    selection.forEach((scan) => {
      scan.selected = select;
      this.selectionChange.emit([scan]);
    });
  }

  private toggleSingleSelected(selectedModel: ScandataModel) {
    const currentSelection = this.dataSource.data.filter((model) => model.selected);
    const inCurrentSelection = currentSelection.find((model) => model === selectedModel);

    currentSelection.forEach((model) => (model.selected = false));

    //unselect model if it's the only current selected model
    if (currentSelection.length === 1 && inCurrentSelection) {
      selectedModel.selected = false;
    } else {
      selectedModel.selected = true;
    }

    this.selectionChange.emit([selectedModel]);
  }

  private sortingDataAccessor(): (data: ScandataModel, sortHeaderId: string) => string | number {
    return (data: ScandataModel, sortHeaderId: string): string | number => {
      const value: string = (data as never)[sortHeaderId];
      return isNumeric(value) ? Number(value) : isNil(value) ? '' : value.toLowerCase();
    };
  }
}

const isEventFromModusCheckbox = (value: Event): boolean => {
  const src = value.target;
  return isDefined(src) && 'tagName' in src && src.tagName === 'MODUS-CHECKBOX';
};
