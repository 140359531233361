import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSwitchModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClassificationScheme } from '../../../classification/classification-scheme.model';
import { colorHexStripAlpha } from '../../../utils/color-converter';
import { Scan3dStyle } from '../../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-classification',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusSwitchModule,
    ModusButtonModule,
    ModusTooltipModule,
    ModusIconModule,
    ColorPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dClassificationComponent implements OnChanges {
  @Input() scan3dStyle!: Scan3dStyle;
  @Output() styleChange = new EventEmitter<Scan3dStyle>();

  classificationSchemes!: ClassificationScheme[];
  showClassificationControl = new FormControl<boolean>(false, { nonNullable: true });

  constructor() {
    this.subscribeToFormChanges();
  }

  ngOnChanges() {
    if (isNil(this.scan3dStyle)) return;

    this.setFormValue();
  }

  changeVisibility(classificationScheme: ClassificationScheme) {
    classificationScheme.visible = !classificationScheme.visible;

    const schemes = this.getClassificationSchemeChanges(classificationScheme);
    this.updateGlobalStyle({ classificationSchemes: schemes });
  }

  changeColor(color: string, classificationScheme: ClassificationScheme) {
    classificationScheme.rgba = color.replace('#', '').toUpperCase();

    const schemes = this.getClassificationSchemeChanges(classificationScheme);
    this.updateGlobalStyle({ classificationSchemes: schemes });
  }

  stripAlpha(rgba: string) {
    return colorHexStripAlpha(rgba);
  }

  private subscribeToFormChanges() {
    this.showClassificationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.updateGlobalStyle({ showClassification: show });
    });
  }

  private setFormValue() {
    const style = this.scan3dStyle;

    this.classificationSchemes = style.classificationSchemes;
    this.showClassificationControl.setValue(style.showClassification, { emitEvent: false });
  }

  private getClassificationSchemeChanges(classificationScheme: ClassificationScheme) {
    return this.scan3dStyle.classificationSchemes.map((scheme) => {
      if (scheme.id === classificationScheme.id) {
        return classificationScheme;
      }
      return scheme;
    });
  }

  private updateGlobalStyle(style: Partial<Scan3dStyle>) {
    const mergedStyle: Scan3dStyle = {
      ...this.scan3dStyle,
      ...style,
    };
    this.styleChange.emit(mergedStyle);
  }
}
