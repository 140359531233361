import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, catchError, forkJoin, switchMap } from 'rxjs';
import { PatchScandataModel } from '../scandata/scandata.actions';
import {
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../scandata/scandata.models';
import { ClearCurrentStation, SetCurrentStation } from '../station/station.actions';
import { Station, StationDisplayStatus, StationLoadStatus } from '../station/station.models';
import { StationService } from '../station/station.service';
import { StationState } from '../station/station.state';
import { TilesetStatus } from '../tileset/tileset.models';
import { TilesetService } from '../tileset/tileset.service';

@Injectable({
  providedIn: 'root',
})
export class Host3dService {
  constructor(
    private store: Store,
    private tilesetService: TilesetService,
    private stationService: StationService,
  ) {}

  showScan(scan: ScandataModel) {
    return forkJoin([this.loadModel(scan), this.loadScanStations(scan)]);
  }

  hideScan(scan: ScandataModel) {
    return this.store.dispatch(
      new PatchScandataModel({
        id: scan.id,
        displayStatus: ScandataDisplayStatus.AwaitingHide,
        showInScene: false,
      }),
    );
  }

  loadScanStations(scan: ScandataModel) {
    if (scan.stationLoadStatus === StationLoadStatus.Loading) return EMPTY;

    return this.store
      .dispatch(
        new PatchScandataModel({
          id: scan.id,
          stationLoadStatus: StationLoadStatus.Loading,
        }),
      )
      .pipe(
        switchMap(() => this.stationService.getStations(scan.id)),
        switchMap((stations) =>
          this.store.dispatch(
            new PatchScandataModel({
              id: scan.id,
              stations,
              stationLoadStatus: StationLoadStatus.Loaded,
            }),
          ),
        ),
        catchError(() =>
          this.store.dispatch(
            new PatchScandataModel({
              id: scan.id,
              stationLoadStatus: StationLoadStatus.LoadingError,
            }),
          ),
        ),
      );
  }

  setCurrentStation(station: Station) {
    const currentStation$ = this.store.selectOnce(StationState.currentStation);

    return currentStation$.pipe(
      switchMap((currentStation) =>
        currentStation?.station.id === station.id
          ? this.store.dispatch(new ClearCurrentStation())
          : this.store.dispatch(
              new SetCurrentStation({
                station,
                displayStatus: StationDisplayStatus.AwaitingDisplay,
              }),
            ),
      ),
    );
  }

  private loadModel(scan: ScandataModel) {
    return this.store
      .dispatch(
        new PatchScandataModel({
          id: scan.id,
          loadStatus: ScandataLoadStatus.Loading,
        }),
      )
      .pipe(
        switchMap(() => this.tilesetService.getTilesets(scan.id)),
        switchMap((tilesets) =>
          this.store.dispatch(
            new PatchScandataModel({
              id: scan.id,
              tilesets,
              loadStatus: tilesets.some((t) => t.status !== TilesetStatus.Ready)
                ? ScandataLoadStatus.LoadingError
                : ScandataLoadStatus.Loaded,
              displayStatus: ScandataDisplayStatus.Hidden,
              showInScene: true,
            }),
          ),
        ),
        catchError(() =>
          this.store.dispatch(
            new PatchScandataModel({
              id: scan.id,
              loadStatus: ScandataLoadStatus.LoadingError,
            }),
          ),
        ),
      );
  }
}
