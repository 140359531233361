import { Injectable, inject } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  StateOperator,
  createSelector,
} from '@ngxs/store';
import { ExistingState, patch } from '@ngxs/store/operators';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  AddToDownload,
  ClearAllDownloads,
  ClearCompletedDownloads,
  PatchDownloadFile,
} from './download.actions';
import { DownloadFile, DownloadProgress, DownloadScan, DownloadStatus } from './download.model';
import { DownloadService } from './download.service';

export interface DownloadStateModel {
  downloads: DownloadScan[];
}

const defaultState: DownloadStateModel = {
  downloads: [],
};

@State<DownloadStateModel>({
  name: 'downloadState',
  defaults: defaultState,
})
@Injectable()
export class DownloadState implements NgxsAfterBootstrap {
  private downloadService = inject(DownloadService);

  ngxsAfterBootstrap(): void {
    this.downloadService.subscribeDownloadWatcher();
  }

  static getDownloadsProgress(
    downloadFiles: DownloadFile[],
  ): (state: DownloadStateModel) => DownloadProgress | null {
    return createSelector([DownloadState], () => {
      return getProgress(downloadFiles);
    });
  }

  @Selector() static downloads(state: DownloadStateModel): DownloadScan[] {
    return state.downloads;
  }

  @Selector() static activeDownloadCount(state: DownloadStateModel): number {
    const busy = state.downloads.flatMap((scan) =>
      scan.files.filter(
        (file) =>
          file.downloadProgress?.status === DownloadStatus.Pending ||
          file.downloadProgress?.status === DownloadStatus.Busy,
      ),
    );
    return busy.length;
  }

  @Action(AddToDownload)
  addToDownload(
    ctx: StateContext<DownloadStateModel>,
    { downloadScan, downloadFile }: AddToDownload,
  ) {
    const downloads = ctx.getState().downloads;

    const scan: DownloadScan = downloads.find(
      (scan) => scan.scandataModel.id === downloadScan.scandataModel.id,
    ) ?? { ...downloadScan, ...{ files: [] } };

    const hasFile = scan.files.find(
      (file) => file.scandataFile.filename === downloadFile.scandataFile.filename,
    );

    if (hasFile) return;

    const updateScan = { ...scan, files: [...scan.files, downloadFile] };

    ctx.setState(patch<DownloadStateModel>({ downloads: updateDownloadScan(updateScan) }));
  }

  @Action(PatchDownloadFile)
  patchDownloadFile(ctx: StateContext<DownloadStateModel>, { downloadFile }: PatchDownloadFile) {
    ctx.setState(patch<DownloadStateModel>({ downloads: updateDownloadFile(downloadFile) }));
  }

  @Action(ClearAllDownloads)
  clearAllDownloads(ctx: StateContext<DownloadStateModel>) {
    ctx.patchState({ downloads: [] });
  }

  @Action(ClearCompletedDownloads)
  clearCompletedDownloads(ctx: StateContext<DownloadStateModel>) {
    const downloads = ctx.getState().downloads;
    const incompleteDownloads = downloads
      .map((scan) => {
        scan.files = scan.files.filter(
          (file) =>
            file.downloadProgress?.status === DownloadStatus.Pending ||
            file.downloadProgress?.status === DownloadStatus.Busy,
        );

        return scan;
      })
      .filter((scan) => scan.files.length > 0);

    ctx.setState(patch<DownloadStateModel>({ downloads: incompleteDownloads }));
  }
}

export function downloadsChanged(previous: DownloadScan[], current: DownloadScan[]): boolean {
  const currentFiles = current.flatMap((scan) => scan.files);
  const previousFiles = previous.flatMap((scan) => scan.files);

  const filesMatch =
    current.length === previous.length &&
    currentFiles.length === previousFiles.length &&
    currentFiles.every((currentFile) => {
      return isDefined(
        previousFiles.find(
          (previousFile) =>
            previousFile.pointcloudId === currentFile.pointcloudId &&
            previousFile.scandataFile.filename === currentFile.scandataFile.filename &&
            isDefined(previousFile.downloadProgress) === isDefined(currentFile.downloadProgress),
        ),
      );
    });

  return filesMatch;
}

function updateDownloadScan(downloadScan: DownloadScan): StateOperator<DownloadScan[]> {
  return (existing: ExistingState<DownloadScan[]>) => {
    const update = existing.filter(
      (scan) => scan.scandataModel.id !== downloadScan.scandataModel.id,
    );

    update.push(downloadScan);
    return update;
  };
}

function updateDownloadFile(downloadFile: DownloadFile): StateOperator<DownloadScan[]> {
  return (existing: ExistingState<DownloadScan[]>) => {
    const update = [...existing];

    const scan = update.find((item) =>
      item.files.find((file) => file.pointcloudId === downloadFile.pointcloudId),
    );
    if (isNil(scan)) return update;

    scan.files = scan.files.map((file) => {
      if (file.scandataFile.filename !== downloadFile.scandataFile.filename) return file;
      return downloadFile;
    });

    return update;
  };
}

function getProgress(files: DownloadFile[]): DownloadProgress | null {
  const hasFiles = files.length > 0;
  if (!hasFiles) return null;

  const downloadingAll = files.every((file) => isDefined(file.downloadProgress));
  if (!downloadingAll) return null;

  if (files.length === 1) return files[0].downloadProgress ?? null;

  const hasIncompleteDownloads =
    files.filter(
      (file) =>
        file.downloadProgress?.status === DownloadStatus.Pending ||
        file.downloadProgress?.status === DownloadStatus.Busy,
    ).length > 0;

  const percentageComplete = hasIncompleteDownloads ? getPercentageComplete(files) : 100;

  const hasFailure = files.some((file) => file.downloadProgress?.status === DownloadStatus.Failed);

  const status = hasIncompleteDownloads
    ? DownloadStatus.Busy
    : hasFailure
      ? DownloadStatus.Failed
      : DownloadStatus.Complete;

  const downloadProgress: DownloadProgress = {
    status,
    percentageComplete,
    content: null,
  };

  return downloadProgress;
}

function getPercentageComplete(files: DownloadFile[]) {
  const totalBytes = files.reduce((total, file) => {
    return total + file.scandataFile.fileSizeGigaBytes * 1024 * 1024;
  }, 0);

  const completedBytes = files.reduce((total, file) => {
    const progress = file.downloadProgress?.percentageComplete ?? 0;
    if (progress === 0) return total;

    const fileCompletedBytes =
      ((file.scandataFile.fileSizeGigaBytes * 1024 * 1024) / 100) * progress;

    return total + fileCompletedBytes;
  }, 0);

  const percentageComplete = Number(
    totalBytes ? ((100 * completedBytes) / totalBytes).toFixed(1) : 0,
  );

  return percentageComplete;
}
