import { BBox } from 'geojson';
import { ScandataModel } from '../scandata/scandata.models';

export const DEFAULT_BBOX: BBox = [-45, -45, 45, 45]; //Shows world at zoom level 2
export const DEFAULT_BOUNDS_PADDING = 50;
export const MAP_MAX_ZOOM = 20;

export enum MapFilterOption {
  All = 'All',
  LocatedOnly = 'LocatedOnly',
}

export interface MapScandataModel extends ScandataModel {
  hasFeature: boolean;
}

export interface MapBounds {
  bbox: BBox;
  padding?: number;
}
