import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { ScandataModel } from '../../../../scandata/scandata.models';

@UntilDestroy()
@Component({
  selector: 'sd-options',
  standalone: true,
  imports: [ModusTooltipModule, ModusButtonModule, ModusIconModule],
  templateUrl: './options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsComponent {
  @HostBinding('class') class = 'flex flex-col h-full';

  @Input() scandataModels!: ScandataModel[];
  @Input() disabled!: boolean;
  @Input() readonly = false;

  @Output() viewIn3dClicked = new EventEmitter();
  @Output() editTagsClicked = new EventEmitter();
  @Output() downloadClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() removeAllClicked = new EventEmitter();
  @Output() removeModelClicked = new EventEmitter<ScandataModel>();
}
