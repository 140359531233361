import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { isDefined, isNil } from '@trimble-gcs/common';
import bbox from '@turf/bbox';
import { Feature } from 'geojson';
import {
  Draw,
  DrawEvents,
  DrawMap,
  Icon,
  IconOptions,
  LatLng,
  LatLngBounds,
  Layer,
  LeafletEvent,
  Map,
  MapOptions,
  PathOptions,
  Point,
  PointExpression,
  Polygon,
  Rectangle,
  geoJSON,
  icon,
  latLng,
  marker,
  tileLayer,
} from 'leaflet';

import { BaseLayer } from '../base-layer/base-layer.models';
import { FeatureLayer, NORMAL_STYLE, SELECTED_STYLE } from '../feature-layer/feature-layer.models';
import { MapBounds } from '../map.models';
import { MapTool } from '../map.state';
import {
  calculateCentroid,
  convertBBoxToLeafletBounds,
  convertLeafletBoundsToBBox,
  getBBoxWidthAndHeight,
  intersects,
  offsetPolygon,
} from '../map.util';
import { MarkerLayer } from './marker-layer';

const DOUBLE_CLICK_TIMEOUT = 300;
const ZOOM_THRESHOLD = 14;
const SHOW_POLYGON_THRESHOLD = 0.2;
const MAP_LAT_MIN = -90;
const MAP_LAT_MAX = 90;

const defaultOptions = {
  attributionControl: false,
  boxZoom: false,
  zoom: 0,
  minZoom: 0,
  zoomControl: false,
  zoomSnap: 0.001,
  center: latLng(0, 0),
  maxBounds: new LatLngBounds(
    new LatLng(MAP_LAT_MIN, Number.NEGATIVE_INFINITY),
    new LatLng(MAP_LAT_MAX, Number.POSITIVE_INFINITY),
  ),
  maxBoundsViscosity: 1,
  worldCopyJump: true,
} satisfies MapOptions;

const drawShapeOptions: PathOptions = {
  weight: 3,
  opacity: 1,
  color: '#FBAD26',
  fillColor: '#FBAD26',
};

const drawMarker = Icon.extend({
  options: {
    iconAnchor: new Point(5, 5),
    iconUrl: 'assets/leaflet/draw-marker.png',
  },
});

export interface FeatureClickedEvent {
  feature: Feature;
  hasModifierKey: boolean;
}

@Component({
  selector: 'sd-map-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeafletModule, LeafletDrawModule],
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss'],
})
export class MapViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('map') private mapElement!: ElementRef;

  private _featureLayer!: FeatureLayer | null;
  private _leafletMap!: Map;
  private _zoom = defaultOptions.zoom;
  private _polygonLayer: Layer = geoJSON();
  private _markerLayer: Layer = geoJSON();
  private _resizeObserver!: ResizeObserver;
  private _drawShape?: Draw.Rectangle | Draw.Polygon;
  private _bounds!: MapBounds;
  private _currentBounds!: LatLngBounds;

  private get drawMap() {
    return this._leafletMap as DrawMap;
  }

  tileLayer?: Layer;
  scanLayer: Layer = geoJSON();
  toolLayer: Layer = geoJSON();
  options: MapOptions = defaultOptions;

  @Input() set baseLayer(baseLayer: BaseLayer | null) {
    this.tileLayer = isDefined(baseLayer) ? this.createTileLayer(baseLayer) : undefined;
  }

  @Input() set featureLayer(featureLayer: FeatureLayer | null) {
    this._featureLayer = featureLayer;
    this.createDataLayers();
    this.updateDataLayer();
  }

  @Input() set bounds(bounds: MapBounds) {
    this._bounds = bounds;
    this.fitMapToBounds();
  }

  @Input() set activeMapTool(mapTool: MapTool) {
    this._drawShape?.disable();

    switch (mapTool) {
      case MapTool.RectangleSelect:
        this.drawRectangleSelect();
        break;

      case MapTool.PolygonSelect:
        this.drawPolygonSelect();
        break;
    }

    this.createDataLayers();
    this.updateDataLayer();
  }

  @Output() featureClicked = new EventEmitter<FeatureClickedEvent>();
  @Output() featureDblClicked = new EventEmitter<FeatureClickedEvent>();
  @Output() featuresSelected = new EventEmitter<Feature[]>();
  @Output() mapToolSelected = new EventEmitter<MapTool>();
  @Output() boundsChanged = new EventEmitter<MapBounds>();

  ngAfterViewInit() {
    this.registerResizeObserver();
  }

  ngOnDestroy(): void {
    const bbox = convertLeafletBoundsToBBox(this._currentBounds);
    this.boundsChanged.emit({ bbox });

    this._resizeObserver.disconnect();
  }

  zoomed(event: LeafletEvent) {
    this._zoom = event.target.getZoom();
    this.setWorldCopyInBounds();
    this.updateDataLayer();
  }

  zoomIn() {
    this._leafletMap?.zoomIn();
  }

  zoomOut() {
    this._leafletMap?.zoomOut();
  }

  moved() {
    this.setWorldCopyInBounds();
    this.updateDataLayer();

    this._currentBounds = this._leafletMap.getBounds();
  }

  setMapInstance(map: Map) {
    this._leafletMap = map;

    this.addMapRightClickHandler();
    this.addMapDrawHandler();
    this.addPanHandler();
  }

  private drawRectangleSelect() {
    this._drawShape = new Draw.Rectangle(this.drawMap, {
      shapeOptions: drawShapeOptions,
      repeatMode: true,
    });
    this._drawShape.enable();
  }

  private drawPolygonSelect() {
    this._drawShape = new Draw.Polygon(this.drawMap, {
      shapeOptions: drawShapeOptions,
      repeatMode: true,
      icon: new drawMarker(),
    });
    this._drawShape.enable();
  }

  private addMapRightClickHandler() {
    this._leafletMap.on('mousedown', (event) => {
      if (event.originalEvent.button === 0) return;

      this._drawShape?.disable();
      this.mapToolSelected.emit(MapTool.Default);
    });

    this._leafletMap.on('contextmenu', (event) => {
      event.originalEvent.stopPropagation();
    });
  }

  private addMapDrawHandler() {
    // This is a hack due to a bug in leaflet.draw
    // https://github.com/Leaflet/Leaflet.draw/issues/1005
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).type = undefined;

    this._leafletMap.on('draw:canceled', () => this.mapToolSelected.emit(MapTool.Default));

    this._leafletMap.on(Draw.Event.CREATED, (event) => {
      const features = this._featureLayer?.featureCollection?.features;
      if (isNil(features) || features.length === 0) return;

      const { layer } = event as DrawEvents.Created;
      const selectPolygon = layer as Polygon | Rectangle;
      const { geometry: selectGeoJsonPolygon } = selectPolygon.toGeoJSON() as Feature<
        GeoJSON.Polygon,
        unknown
      >;

      //copy the selection shape to the center map where we can test for intersect against the features
      const selectPolygons = this.getGreenwichLongitudesInBounds(selectPolygon.getBounds()).map(
        (offset) => {
          return offsetPolygon(selectGeoJsonPolygon, offset * -1);
        },
      );

      const intersectingFeatures = features.filter((feature) => {
        if (feature.geometry.type !== 'Polygon') return false;
        const featurePolygon = feature.geometry as GeoJSON.Polygon;

        return selectPolygons.some((selectPoly) => {
          return intersects(selectPoly, featurePolygon);
        });
      });

      this.featuresSelected.emit(intersectingFeatures);
    });
  }

  /**
   * Get each greenwhich meridian inside the bounds
   * Starts at zero and moves outwards until the bounds are contained.
   */
  private getGreenwichLongitudesInBounds(bounds: LatLngBounds) {
    let west = -180;
    while (west > bounds.getWest()) west -= 360;

    let east = 180;
    while (east < bounds.getEast()) east += 360;

    const offsets = [];
    for (let offset = west + 180; offset <= east - 180; offset += 360) {
      offsets.push(offset);
    }

    return offsets;
  }

  private createTileLayer(baseLayer: BaseLayer): Layer {
    const { templateUrl, maxZoom } = baseLayer;

    return tileLayer(templateUrl, {
      maxZoom: maxZoom,
    });
  }

  private createDataLayers() {
    this._polygonLayer = this.createPolygonLayer();
    this._markerLayer = this.createMarkerLayer();
  }

  private createPolygonLayer(): Layer {
    if (isNil(this._featureLayer)) return geoJSON();

    const featureClicked = this.featureClicked;

    return geoJSON(this._featureLayer.featureCollection, {
      interactive: !this._drawShape?.enabled(),
      style: function (feature) {
        if (feature?.properties.selected) {
          return SELECTED_STYLE;
        } else {
          return NORMAL_STYLE;
        }
      },
      filter: function (feature) {
        return feature?.properties.visible;
      },
      onEachFeature: function onEachFeature(feature, layer) {
        layer.on({
          click: ($event) => {
            const hasModifier = $event.originalEvent.ctrlKey || $event.originalEvent.shiftKey;
            featureClicked.emit({ feature, hasModifierKey: hasModifier });
          },
        });
      },
    });
  }

  private createMarkerLayer(): Layer {
    const markerLayer = new MarkerLayer();
    const markers = this.createMarkers();

    markers?.forEach((marker) => markerLayer.addMarker(marker));

    return markerLayer;
  }

  private createMarkers() {
    if (isNil(this._featureLayer)) return;

    const featureClicked = this.featureClicked;
    const featureDblClicked = this.featureDblClicked;

    const markers =
      this._featureLayer.featureCollection?.features
        .filter((feature) => feature?.properties?.['visible'])
        .map((feature) => {
          const centroid = calculateCentroid(feature);
          const [lng, lat] = centroid.geometry.coordinates;

          const iconName = feature?.properties?.['selected'] ? 'selected' : 'marker';

          const iconOptions: IconOptions = {
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            shadowUrl: 'assets/leaflet/marker-shadow.png',
            iconUrl: `assets/leaflet/${iconName}.png`,
            iconRetinaUrl: `assets/leaflet/${iconName}-2x.png`,
          };

          let clickHandled = false;

          return marker(latLng(lat, lng), {
            icon: icon(iconOptions),
            interactive: !this._drawShape?.enabled(),
          })
            .on('click', ($event) => {
              clickHandled = false;
              window.setTimeout(() => {
                if (!clickHandled) {
                  const hasModifier = $event.originalEvent.ctrlKey || $event.originalEvent.shiftKey;
                  featureClicked.emit({ feature, hasModifierKey: hasModifier });
                }
              }, DOUBLE_CLICK_TIMEOUT);
            })
            .on('dblclick', ($event) => {
              clickHandled = true;

              const hasModifier = $event.originalEvent.ctrlKey || $event.originalEvent.shiftKey;
              featureDblClicked.emit({ feature, hasModifierKey: hasModifier });
            });
        }) || [];

    return markers;
  }

  private updateDataLayer() {
    const showPolygonLayer = this._zoom >= ZOOM_THRESHOLD || this.showFeaturePolygon();

    if (showPolygonLayer) {
      this.scanLayer = this._polygonLayer;
    } else {
      this.scanLayer = this._markerLayer;
    }
  }

  private showFeaturePolygon() {
    if (isNil(this._featureLayer?.featureCollection)) return false;

    const mapBounds = this._leafletMap?.getBounds();
    if (isNil(mapBounds)) return false;

    const mapBBox = convertLeafletBoundsToBBox(mapBounds);

    return this._featureLayer.featureCollection.features.some((feature) => {
      const featureBounds = geoJSON(feature).getBounds();
      if (!mapBounds.intersects(featureBounds)) return false;

      const mapSize = getBBoxWidthAndHeight(mapBBox);
      const featureSize = getBBoxWidthAndHeight(bbox(feature));

      const featureWidthRatio = featureSize.width / mapSize.width;
      const featureHeightRatio = featureSize.height / mapSize.height;

      return (
        featureWidthRatio >= SHOW_POLYGON_THRESHOLD || featureHeightRatio >= SHOW_POLYGON_THRESHOLD
      );
    });
  }

  private registerResizeObserver() {
    let firstResizeEvent = true;

    this._resizeObserver = new ResizeObserver(() => {
      this._leafletMap.invalidateSize({ pan: false, animate: false, debounceMoveend: false });
      this.setMinZoom();

      if (firstResizeEvent) {
        firstResizeEvent = false;

        /**
         * Ideally we would fit bounds on either setMapInstance or afterViewInit.
         *
         * The problem is that when you switch tabs, material hasn't finished setting
         * the final size of the container until this resize event fires.
         *
         * The result is that leaflet has cached the incorrect container size, and
         * wont set the bounds correctly.
         * (also note leaflets internal resizeObserver should be invalidating size,
         * but it is not working as at v1.9.4)
         *
         * Therefore we can only reliably set the bounds after the resize event and
         * after calling leaflet invalidateSize.
         *
         * So here we are checking if this is component init (via firstResize) and
         * then fitting the map to the input bounds supplied.
         */
        this.fitMapToBounds();
      }
    });

    this._resizeObserver.observe(this.mapElement.nativeElement);
  }

  /**
   * worldCopyJump = true is suppose to take care of this,
   * but it only works when you pan with mouse down or the
   * keyboard.
   * It does not work when panning with inertia or zoom.
   * This method does what worldCopyJump is suppose to do.
   */
  private setWorldCopyInBounds() {
    const map = this._leafletMap;
    const center = map.getCenter();
    const newCenter = map.wrapLatLng(center);

    if (center.equals(newCenter)) return;

    map.panTo(newCenter, { animate: false, noMoveStart: true });
  }

  private setMinZoom() {
    const minBounds = new LatLngBounds(new LatLng(MAP_LAT_MIN, 0), new LatLng(MAP_LAT_MAX, 0));
    const map = this._leafletMap;

    /**
     * getBoundsZoom takes the current min zoom into account. We have to
     * first set min zoom back to zero before calling getBoundsZoom.
     *
     * leaflet.setMinZoom updates the map options, but it also calls setView
     * which produces incorrect results when immediatly calling fitBounds thereafter
     * (but only for small bounds with for example a zoom level 18).
     *
     * setMinZoom does not have a config options parameter, so you can't set
     * animation=false which should fix the problem.
     *
     * Calling map.stop() before fitBounds should have fixed the issue but
     * it has no effect and fitBounds still produces the wrong results.
     *
     * In Summary: the code below does what setMinZoom does, but without the
     * animation.
     */

    map.options.minZoom = 0;
    map.options.minZoom = map.getBoundsZoom(minBounds);

    if (map.getZoom() < map.options.minZoom) {
      map.setZoom(map.options.minZoom, { animate: false });
    }
  }

  private fitMapToBounds() {
    if (isNil(this._bounds) || isNil(this._leafletMap)) return;

    const leafletBounds = convertBBoxToLeafletBounds(this._bounds.bbox);
    const paddingValue = this._bounds.padding ?? 0;
    const padding: PointExpression = [paddingValue, paddingValue];

    this._leafletMap.fitBounds(leafletBounds, { padding });
  }

  /**
   * While panning with inertia the map should immediatly stop on mouse down,
   * leaflet does not do this out the box, this method fixes that.
   */
  private addPanHandler() {
    const map = this._leafletMap;
    let panning = false;

    map.on('movestart', () => (panning = true));
    map.on('moveend', () => (panning = false));
    map.on('mousedown', () => {
      if (panning) map.stop();
    });
  }
}
